import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import useAxios from '../hooks/useAxios';

const ReportJobModal = ({ isOpen, onRequestClose, jobId, jobTitle, companyName }) => {
    const [reason, setReason] = useState('');
    const [description, setDescription] = useState('');
    const { post } = useAxios();

    useEffect(() => {
        if (!isOpen) {
            setReason('');
            setDescription('');
        }
    }, [isOpen]);

    const handleReport = async () => {
        try {
            await post(
                '/report-job',
                { job_id: jobId, reason, description },
                { useAuth: true }
            );
            toast.success('Job reported successfully!');
            onRequestClose();
        } catch (error) {
            toast.error('Error reporting job');
            console.error('Error reporting job:', error);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Report Job"
            className="flex items-center justify-center fixed inset-0 z-50"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        >
            <div className="bg-white rounded-lg p-6 shadow-lg w-full max-w-md mx-auto">
                <h2 className="text-lg font-semibold mb-4">Report Job: {jobTitle} at {companyName}</h2>
                <form>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Reason</label>
                        <select
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            required
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        >
                            <option value="" disabled>Select a reason</option>
                            <option value="Scam">Scam</option>
                            <option value="Fraud">Fraud</option>
                            <option value="Discrimination">Discrimination</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Description</label>
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            rows="3"
                        ></textarea>
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button
                            type="button"
                            onClick={onRequestClose}
                            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={(e) => { e.preventDefault(); handleReport(); }}
                            className="px-4 py-2 bg-green-500 text-white rounded-md shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        >
                            Submit Report
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default ReportJobModal;
