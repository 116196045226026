import React, { useState, useEffect, useRef } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import useAxios from '../hooks/useAxios';
import { useError } from '../hooks/useError';
import { useLoad } from '../hooks/useLoad';
import SimpleSearch from '../JobseekerDashBoard/components/SimpleSearch';

const slugify = (text) => {
  return text.toString().toLowerCase().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-').replace(/^-+/, '').replace(/-+$/, '');
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slick-arrow`}
      style={{ 
        ...style, 
        display: "block", 
        background: "green", 
        borderRadius: "50%",
        right: '10px', // Adjust the distance from the right edge
        zIndex: 1, // Ensure it's above the content
      }}
      onClick={onClick}
    />
  );
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slick-arrow`}
      style={{ 
        ...style, 
        display: "block", 
        background: "green", 
        borderRadius: "50%",
        left: '10px', // Adjust the distance from the left edge
        zIndex: 1, // Ensure it's above the content
      }}
      onClick={onClick}
    />
  );
};

const JobCategories = ({ layout = 'slider' }) => {
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [keyword, setKeyword] = useState('');
  const { get } = useAxios();
  const { setError } = useError();
  const { setLoading } = useLoad();
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const containerRef = useRef(null);

  useEffect(() => {
    const fetchCompanies = async () => {
      setLoading(true);
      try {
        const response = await get('/companies');
        const formattedCompanies = response.map((company) => ({
          id: company.id,
          name: company.name,
          logo: company.logo,
          industry: company.industry,
          available_jobs: company.available_jobs
        }));
        setCompanies(formattedCompanies);
        setFilteredCompanies(formattedCompanies);
      } catch (error) {
        setError('Error fetching companies');
      } finally {
        setLoading(false);
      }
    };

    fetchCompanies();
  }, [get, setError, setLoading]);

  const settings = {
    dots: companies.length <= 10,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: companies.length <= 10,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: companies.length <= 10,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: companies.length <= 10,
        }
      }
    ]
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSearch = () => {
    if (keyword.trim() === '') {
      setFilteredCompanies(companies);
    } else {
      const filtered = companies.filter(company => company.name.toLowerCase().includes(keyword.toLowerCase()));
      setFilteredCompanies(filtered);
    }
    setCurrentPage(1);
  };

  useEffect(() => {
    if (keyword === '') {
      setFilteredCompanies(companies);
      setCurrentPage(1);
    }
  }, [keyword, companies]);

  const indexOfLastCompany = currentPage * itemsPerPage;
  const indexOfFirstCompany = indexOfLastCompany - itemsPerPage;
  const paginatedCompanies = filteredCompanies.slice(indexOfFirstCompany, indexOfLastCompany);

  return (
    <section className="py-4 md:py-6 lg:py-8 mb-4 bg-gray-100" ref={containerRef}>
      <div className="container mx-auto text-center px-4">
        {layout === 'grid' && <SimpleSearch keyword={keyword} setKeyword={setKeyword} handleSearch={handleSearch} />}
        <h2 className="text-2xl md:text-3xl font-bold mb-4 text-green-600">Employers Currently Hiring</h2>
        <p className="mb-4 text-gray-700">Broaden your job search with curated collections</p>
        {layout === 'slider' ? (
          <div className="relative">
            <Slider {...settings}>
              {companies.map((company, index) => (
                <div key={index} className="p-4">
                  <Link to={`/companies/${company.id}-${slugify(company.name)}`}>
                    <div className="bg-white rounded-lg shadow-md transform hover:scale-105 transition duration-500 ease-in-out w-64 h-64 mx-auto flex flex-col items-center">
                      <div className="h-24 w-24 mx-auto mb-4 flex items-center justify-center mt-4">
                        <img src={company.logo} alt={company.name} className="max-w-full max-h-full object-contain" />
                      </div>
                      <h3 className="text-lg font-bold mb-2 text-green-600 hover:underline text-center line-clamp-2">{company.name}</h3>
                      <p className="text-sm text-gray-600">Industry: {company.industry}</p>
                      <p className="text-sm text-gray-600">Available jobs: {company.available_jobs}</p>
                      <Link to={`/companies/${company.id}-${slugify(company.name)}`} className="bg-green-500 text-white py-1 px-3 rounded mt-auto mb-4">View Details</Link>
                    </div>
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        ) : (
          <div>
            {paginatedCompanies.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {paginatedCompanies.map((company, index) => (
                  <div key={index} className="bg-white p-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col items-center">
                    <div className="h-24 w-24 mx-auto mb-4 flex items-center justify-center">
                      <img src={company.logo} alt={company.name} className="max-w-full max-h-full object-contain" />
                    </div>
                    <h2 className="text-lg font-bold text-gray-800 mb-2 hover:underline text-center line-clamp-2">{company.name}</h2>
                    <p className="text-sm text-gray-600">Industry: {company.industry}</p>
                    <p className="text-sm text-gray-600">Available jobs: {company.available_jobs}</p>
                    <Link to={`/companies/${company.id}-${slugify(company.name)}`} className="bg-green-500 text-white py-1 px-3 rounded mt-auto mb-4">View Details</Link>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center w-full py-10">
                <div className="mb-5">
                  <img src="https://i.pinimg.com/236x/f1/08/f9/f108f928ab6566f49a93d1962b6251f0.jpg" alt="No Results" className="w-40 h-40 sm:w-60 sm:h-60 mx-auto" />
                </div>
                <p className="text-center text-gray-600">No companies matching your search criteria</p>
              </div>
            )}
            <div className="flex justify-center mt-8">
              <button
                className={`px-4 py-2 mx-1 ${currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-green-500 text-white'}`}
                onClick={() => handlePageChange(null, currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <button
                className={`px-4 py-2 mx-1 ${currentPage * itemsPerPage >= filteredCompanies.length ? 'bg-gray-300 cursor-not-allowed' : 'bg-green-500 text-white'}`}
                onClick={() => handlePageChange(null, currentPage + 1)}
                disabled={currentPage * itemsPerPage >= filteredCompanies.length}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default JobCategories;
