import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './layouts/navbar';
import Footer from './layouts/Footer';
import useAxios from './hooks/useAxios';
import { useError } from './hooks/useError';
import { useLoad } from './hooks/useLoad';
import Login from './Authentication/Login';
import SignUp from './Authentication/SignUp';
import EmployerSignup from './Authentication/EmployerSignup';
import EditJob from './EmployerDashBoard/MyjobsById';
import ReportJobModal from './JobseekerDashBoard/ReportJobModal';
import Breadcrumb from './layouts/Breadcrumb';
import CompanyDetails from './components/CompanyDetails';
// import Popup from './JobseekerDashBoard/components/Popup'; 
import JobseekerDetailProfile from './JobseekerDashBoard/JobseekerDetailProfile'; // Import the JobseekerDetailProfile component
import TeamMemberDashboard from './TeamMember/TeamMemberDashboard';
import CompaniesPage from './JobseekerDashBoard/components/CompaniesPage';

const Home = lazy(() => import('./pages/Home'));
const Findjobs = lazy(() => import('./pages/Findjobs'));
const NotFound = lazy(() => import('./pages/NonFound'));
const MyJobs = lazy(() => import('./EmployerDashBoard/MyJobs'));
const ApplicantForm = lazy(() => import('./EmployerDashBoard/Applicants'));
const MyTeam = lazy(() => import('./EmployerDashBoard/MyTeam'));
const Myteamdetails = lazy(() => import('./EmployerDashBoard/components/Myteamdetails'));
const PostJob = lazy(() => import('./EmployerDashBoard/PostJob'));
const MyDocuments = lazy(() => import('./JobseekerDashBoard/MyDocuments'));
const JobseekerSignupForm = lazy(() => import('./Authentication/JobseekerSignupForm'));
const SavedJobs = lazy(() => import('./JobseekerDashBoard/components/SavedJobs'));
const AppliedJobs = lazy(() => import('./JobseekerDashBoard/components/AppliedJobs'));
const JobDetailsPage = lazy(() => import('./JobseekerDashBoard/components/JobDetailsPage'));
const JobDetailView = lazy(() => import('./JobseekerDashBoard/components/JobDetailView'));
const AdminDashboard = lazy(() => import('./Admin/AdminDashboard'));
const EditEmployer = lazy(() => import('./EmployerDashBoard/components/EditEmployer'));
const UpdateProfile = lazy(() => import('./JobseekerDashBoard/UpdateProfile'));
const Statistics = lazy(() => import('./pages/statistics'));
const StatisticsById = lazy(() => import('./pages/StatisticsById'));
const MakePayment = lazy(() => import("./EmployerDashBoard/wallet"))
const EmployerWallet = lazy(() => import('./EmployerDashBoard/wallet/WalletDash'))
// const AdminTransactionHistory = lazy(() => import('./Admin/Transation'))

function App() {
  const { get } = useAxios();
  const [jobs, setJobs] = useState([]);
  const [user, setUser] = useState(null);
  const [myJobs, setMyJobs] = useState([]);
  const location = useLocation();
  const { setLoading } = useLoad();
  const { setError } = useError();

  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [reportJobData, setReportJobData] = useState(null);
  const [showPopup, setShowPopup] = useState(false); 

  const fetchUser = async () => {
    try {
      const data = await get('/checksession', { useAuth: true });
      setUser(data);
      localStorage.setItem('user', JSON.stringify(data));
    } catch (error) {
      setUser(null);
    }
  };

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      try {
        const data = await get('/jobs', { useAuth: true });
        setJobs(data);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    };

    fetchJobs();
  }, [get, setLoading, setError]);

  useEffect(() => {
    fetchUser();
  }, [get]);

  useEffect(() => {
    if (user) {
      const fetchMyJobs = async () => {
        try {
          const data = await get('/myjobs', { useAuth: true });
          setMyJobs(data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      };

      fetchMyJobs();
    }
  }, [user, get, setLoading]);

  useEffect(() => {
    setError(false);
  }, [location, setError]);

  useEffect(() => {
    const savedUser = localStorage.getItem('user');
    if (savedUser) {
      try {
        setUser(JSON.parse(savedUser));
      } catch (error) {
        console.error('Failed to parse user from localStorage:', error);
        localStorage.removeItem('user');
      }
    } else {
      fetchUser();
    }
  }, []);

  // useEffect(() => {
  //   const specialRoutes = ['/', '/findjobs', '/job/:jobId'];
  //   const match = specialRoutes.some((route) => location.pathname.match(new RegExp(`^${route.replace(':jobId', '\\d+')}$`)));
  //   if (!user && match) {
  //     const timer = setTimeout(() => {
  //       setShowPopup(true);
  //     }, 20000); 

  //     return () => clearTimeout(timer);
  //   } else {
  //     setShowPopup(false);
  //   }
  // }, [user, location.pathname]);

  const isSpecialRoute = !['/login', '/signup', '/signup/jobseeker', '/signup/employer', '*'].includes(location.pathname);
  const isAuthenticated = !!user;
  const isAuthorised = location.pathname !== '/login'

  const openReportModal = (jobId, jobTitle, companyName) => {
    setReportJobData({ jobId, jobTitle, companyName });
    setIsReportModalOpen(true);
  };

  const closeReportModal = () => {
    setIsReportModalOpen(false);
    setReportJobData(null);
  };

  return (
    <div className='bg-green-100'>

      <ToastContainer toastStyle={{ fontSize: '14px' }} toastContainerStyle={{ zIndex: 9999, top: 'auto', bottom: '20px', right: '20px' }} />
      {isSpecialRoute && <Navbar user={user} setUser={setUser} />}
      {isSpecialRoute && (
        <div className='mt-24'>
          <div className='p-2'> 
            <Breadcrumb/>
          </div>
        </div>
      )}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes style={{ minHeight: '75vh' }}>
          <Route path='/login' element={<Login fetchUser={fetchUser} />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/signup/jobseeker' element={<JobseekerSignupForm fetchUser={fetchUser} />} />
          <Route path='/not-authorized' element={<div>Not Authorized</div>} />
          <Route path='/' element={<Home jobs={jobs} user={user} />} />
          <Route path='/findjobs' element={<Findjobs jobs={jobs} user={user} openReportModal={openReportModal} />} />
          <Route path='/signup/employer' element={<EmployerSignup fetchUser={fetchUser} />} />
          <Route path='/update-profile' element={<UpdateProfile />} />
          <Route path='/my-profile' element={<JobseekerDetailProfile />} /> 
          <Route path='/Insights' element={<Statistics />} />
          <Route path='/Insights/:blogid/:blogtitle' element={<StatisticsById />} />
          <Route path='/companies/:companyIdSlug' element={<CompanyDetails user={user} />} /> {/* Pass user prop */}
          <Route path='/companies' element={<CompaniesPage />} /> Add this line
          {isAuthenticated && user && user.role === 'employer' && (
            <>
              <Route path='/myjobs' element={<MyJobs myJobs={myJobs} setMyJobs={setMyJobs} />} />
              <Route path='/applicants' element={<ApplicantForm user={user} myJobs={myJobs} />} />
              <Route path='/myteam' element={<MyTeam jobs={jobs} />} />
              <Route path='/myteam/:memberid/:memberName' element={<Myteamdetails myJobs={myJobs} />} />
              <Route path='/postjob/new' element={<PostJob />} />
              <Route path='/edit-profile' element={<EditEmployer />} />
              <Route path='/myjob/:jobId' element={<EditJob />} />
              <Route path='/appliedjobs' element={<Navigate replace to='/not-authorized' />} />
              <Route path='/savedjobs' element={<Navigate replace to='/not-authorized' />} />
              <Route path="/makepayments" element={<MakePayment />} />
              <Route path='wallet' element={<EmployerWallet/>}/>
            </>
          )}
          {isAuthenticated && user && user.role === 'admin' && <Route path='/admin/*' element={<AdminDashboard user={user} />} />}
          
          {isAuthenticated && user && user.role === 'team member' && <Route path='/team member/*' element={<TeamMemberDashboard />} />}
          {isAuthenticated && user && user.role === 'jobseeker' && (
            <>
              <Route path='/findjobs' element={<Findjobs jobs={jobs} user={user} openReportModal={openReportModal} />} />
              <Route path='/Insights' element={<div>statistics</div>} />
              <Route path='/MyDocuments' element={<MyDocuments />} />
              <Route path='/appliedjobs' element={<AppliedJobs jobs={jobs} />} />
              <Route path='/savedjobs' element={<SavedJobs jobs={jobs} />} />
              <Route path='/findjobs/:jobId/:jobtitle' element={<JobDetailsPage user={user} />} />
              <Route path='/jobupdate/:jobId/:jobtitle' element={<JobDetailView user={user} />} />
              <Route path='/savedjobs/:jobId/:jobtitle' element={<JobDetailView user={user} />} />
              <Route path='/appliedjobs/:jobId/:jobtitle' element={<JobDetailView user={user} />} />
              <Route path='/jobmatch/:jobId' element={<JobDetailView user={user} />} />
              <Route path='/jobdetails/:jobId' element={<JobDetailView user={user} />} />
            </>
          )}
          {!isAuthenticated && (
            <>
              <Route path='/findjobs' element={<Findjobs jobs={jobs} user={user} openReportModal={openReportModal} />} />
              <Route path='/findjobs/:jobId/:jobtitle' element={<JobDetailsPage user={user} openReportModal={openReportModal} />} />
              <Route path='/jobupdate/:jobId/:jobtitle' element={<JobDetailView user={user} />} />
              <Route path='/savedjob/:jobId/:jobtitle' element={<JobDetailView user={user} />} />
              <Route path='/appliedjob/:jobId/:jobtitle' element={<JobDetailView user={user} />} />
            </>
          )}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Suspense>
      {isSpecialRoute && <Footer user={user} />}
      <ReportJobModal
        isOpen={isReportModalOpen}
        onRequestClose={closeReportModal}
        jobId={reportJobData?.jobId}
        jobTitle={reportJobData?.jobTitle}
        companyName={reportJobData?.companyName}
      />
      {/* {showPopup && <Popup onClose={() => setShowPopup(false)} />}  */}
    </div>
  );
}

export default App;
