import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import { FaTimes, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { HiOutlineX, HiOutlineCheck } from 'react-icons/hi';
import PDFViewer from '../../../components/PdfViewer';
import { MenuItem, Select, Step, StepLabel, Stepper, Button } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAxios from '../../../hooks/useAxios';
import { useError } from '../../../hooks/useError';

Modal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    maxHeight: '90vh',
    overflow: 'auto',
    borderRadius: '12px',
    border: '1px solid #E5E7EB',
    padding: '1rem'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
};

const ApplicantDetailsModal = ({ isOpen, onRequestClose, applicant, onNext, onPrev, recruitmentStages, updateApplicantState, updateStageCounts }) => {
  const [selectedStage, setSelectedStage] = useState(applicant.stage_id || recruitmentStages[0].id);
  const [isRejected, setIsRejected] = useState(applicant.is_rejected);
  const { patch } = useAxios();
  const { setError } = useError();

  const handleView = async () => {
    try {
      await patch(`/applicant/${applicant.id}`, {
        is_viewed: true
      }, { useAuth: true });

      updateApplicantState(applicant.id, { is_viewed: true });
    } catch (error) {
      setError(error);
    }
  };

  const handleStageChange = async (stage_id) => {
    if (isRejected) {
      toast.error('Cannot move a rejected applicant to another stage.');
      return;
    }
    try {
      setSelectedStage(stage_id);
      const response = await patch(`/applicant/${applicant.id}`, {
        stage_id,
        is_viewed: false
      }, { useAuth: true });

      const updatedCounts = response.updated_stage_counts;
      updateApplicantState(applicant.id, { stage_id, is_viewed: false });
      updateStageCounts(updatedCounts);

      toast.success('Applicant moved to the selected stage successfully!');
    } catch (error) {
      console.error('Error updating stage:', error);
      toast.error('Failed to update the stage.');
    }
  };

  const handleRejectToggle = async () => {
    try {
      const newRejectionStatus = !isRejected;
      setIsRejected(newRejectionStatus);

      await patch(`/applicant/${applicant.id}`, {
        is_rejected: newRejectionStatus
      }, { useAuth: true });

      updateApplicantState(applicant.id, { is_rejected: newRejectionStatus });

      toast.success(`Applicant ${newRejectionStatus ? 'rejected' : 'unrejected'} successfully!`);
    } catch (error) {
      console.error('Error updating rejection status:', error);
      toast.error('Failed to update the rejection status.');
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleView();
      setSelectedStage(applicant.stage_id);
    }
  }, [isOpen, applicant.stage_id]);

  const getDocumentByType = (type) => {
    return applicant.documents.find(doc => doc.document_type === type);
  };

  const renderDocument = (docType) => {
    const document = getDocumentByType(docType);
    return (
      <>
        <h5 className="font-bold text-center">{docType.replace(/([A-Z])/g, ' $1').trim()}</h5>
        {document ? (
          <PDFViewer url={document.document_file} />
        ) : (
          <p className="text-center mt-2">No provided {docType}</p>
        )}
      </>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
    >
      <div className="flex flex-col h-full mt-20">
        <div className="flex justify-between items-center p-4">
          <button onClick={onPrev} className="text-blue-500 text-2xl"><FaChevronLeft /></button>
          <button onClick={onRequestClose} className="text-red-500 text-3xl"><FaTimes /></button>
          <button onClick={onNext} className="text-blue-500 text-2xl"><FaChevronRight /></button>
        </div>
        <div className="flex flex-col items-center gap-10 p-4 overflow-auto">
          <div>
            <img src={applicant.image} alt="Profile" className="rounded-full h-32 w-32 mx-auto" />
            <h2 className="text-xl font-semibold text-center mt-4">{applicant.first_name} {applicant.surname}</h2>
            <p className="text-gray-600 text-sm text-center mt-1">Email: {applicant.email}</p>
            <p className="text-gray-600 text-sm text-center">Phone number: {applicant.phone_number}</p>
            <p className="p-4 text-justify">{applicant.bio}</p>
            <div className="w-full">
              <Stepper activeStep={recruitmentStages.findIndex(stage => stage.id === selectedStage)} alternativeLabel>
                {recruitmentStages.map((stage) => (
                  <Step key={stage.id}>
                    <StepLabel>{stage.stage_name}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Select
                value={selectedStage}
                onChange={(e) => handleStageChange(e.target.value)}
                fullWidth
                variant="outlined"
                className="mt-2"
              >
                {recruitmentStages.map(stage => (
                  <MenuItem key={stage.id} value={stage.id}>
                    {stage.stage_name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <h5 className="font-bold mt-4">Screening Questions and Answers:</h5>
            {applicant.screening_questions.map((qa, index) => (
              <div key={index} className='mb-3'>
                <h3 className="font-semibold">{index + 1}. {qa.question}:</h3>
                <p className='ml-6'>{qa.answer}</p>
              </div>
            ))}
          </div>
          <div className="w-full mt-8">
            {applicant.requirement_documents.map(docType => (
              <div key={docType} className="mb-4">
                {renderDocument(docType)}
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-around items-center p-4 border-t mt-auto">
          <Button onClick={handleRejectToggle} variant="contained" color={isRejected ? "primary" : "secondary"} startIcon={isRejected ? <HiOutlineCheck /> : <HiOutlineX />}>
            {isRejected ? "Unreject" : "Reject"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ApplicantDetailsModal;
