import React, { useState, useEffect } from 'react';
import useAxios from '../hooks/useAxios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const EditJob = () => {
  const { jobId } = useParams();
  const [formData, setFormData] = useState({
    job_title: "",
    location: "",
    work_type: "",
    deadline_date: "",
    description: "",
    qualification: "",
    job_function: "",
    threshold: "", // New field
    experience: "",
    screening_questions: [],
    required_documents: []
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); 
  const { get, patch } = useAxios();

  const jobFunctionOptions = [
    "Accounting", "Auditing & Finance", "Admin & Office", "Creative & Design", "Building & Architecture",
    "Consulting & Strategy", "Customer Service & Support", "Engineering & Technology", "Farming & Agriculture",
    "Food Services & Catering", "Hospitality & Leisure", "Software & Data", "Legal Services", "Marketing & Communications",
    "Medical & Pharmaceutical", "Product & Project Management", "Estate Agents & Property Management",
    "Quality Control & Assurance", "Human Resources", "Management & Business Development", "Community & Social Services",
    "Sales", "Supply Chain & Procurement", "Research, Teaching & Training", "Driver & Transport Services", "Health & Safety"
  ];

  const experienceOptions = [
    'On-site', 'Remote', 'Hybrid'
  ];

  const requiredDocumentsOptions = [
    'Cv', 'Good Conduct', 'Cover Letter', 'Recommendations', 'Transcripts', 'Driving License', 'Awards', 'Academic Certificate'
  ];

  const counties = [
    "Bomet", "Bungoma", "Busia", "Elgeyo Marakwet", "Embu", "Garissa", "Homa Bay", "Isiolo", "Kajiado", "Kakamega",
    "Kericho", "Kerugoya", "Kisii", "Kisumu", "Kitui", "Kivaa", "Kiambu", "Kilifi", "Kirinyaga", "Kwale", "Laikipia",
    "Lamu", "Machakos", "Makueni", "Mandera", "Marsabit", "Meru", "Migori", "Mombasa", "Murang'a", "Nairobi", "Nakuru",
    "Nandi", "Narok", "Nyamira", "Nyandarua", "Nyeri", "Samburu", "Taita-Taveta", "Tana River", "Tharaka Nithi", 
    "Trans Nzoia", "Turkana", "Uasin Gishu", "Vihiga", "Wajir", "West Pokot"
  ];

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const jobData = await get(`/myjob/${jobId}`, { useAuth: true });
        
        setFormData({
          job_title: jobData.job_title,
          location: jobData.location,
          work_type: jobData.work_type,
          deadline_date: new Date(jobData.deadline_date).toISOString().split('T')[0],
          description: jobData.description,
          qualification: jobData.qualification,
          job_function: jobData.job_function || "",
          threshold: jobData.threshold || "",
          experience: jobData.experience || "",
          screening_questions: jobData.screening_questions.map(q => ({ id: q.id, question: q.question })),
          required_documents: jobData.required_documents
        });
      } catch (error) {
        toast.error('Failed to fetch job data');
      }
    };

    fetchJob();
  }, [jobId, get]);

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = (name === 'job_title' || name === 'location') ? capitalizeWords(value) : value;
    setFormData(prevState => ({
      ...prevState,
      [name]: formattedValue
    }));
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      required_documents: checked
        ? [...prevState.required_documents, value]
        : prevState.required_documents.filter(doc => doc !== value)
    }));
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = formData.screening_questions.map((question, i) => {
      if (i === index) {
        return { ...question, question: value };
      }
      return question;
    });
    setFormData({ ...formData, screening_questions: newQuestions });
  };

  const addScreeningQuestion = () => {
    setFormData({
      ...formData,
      screening_questions: [...formData.screening_questions, { id: null, question: "" }]
    });
  };

  const removeScreeningQuestion = (index) => {
    setFormData({
      ...formData,
      screening_questions: formData.screening_questions.filter((_, i) => i !== index)
    });
  };

  const validate = () => {
    const newErrors = {};

    if (!formData.job_title) newErrors.job_title = 'Job title is required';
    if (!formData.location) newErrors.location = 'Location is required';
    if (!formData.work_type) newErrors.work_type = 'Work type is required';
    if (!formData.deadline_date) newErrors.deadline_date = 'Deadline date is required';
    if (!formData.description) newErrors.description = 'Description is required';
    if (!formData.qualification) newErrors.qualification = 'Qualification is required';
    if (!formData.job_function) newErrors.job_function = 'Job function is required';
    if (!formData.experience) newErrors.experience = 'Experience level is required';
    if (!formData.threshold) newErrors.threshold = 'Threshold is required';

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleEditJob = async (e) => {
    e.preventDefault();

    if (!validate()) {
      toast.error('Please fill out all required fields');
      return;
    }

    

    const formattedData = {
      ...formData,
      screening_questions: formData.screening_questions.filter(q => q.question.trim() !== "")
    };

    try {
      const response = await patch(`/myjob/${jobId}`, formattedData, { useAuth: true });
      toast.success(response.message);
    } catch (error) {
      toast.error('Failed to update job');
    }
  };

  return (
    <div className="flex flex-wrap mt-24 mx-auto w-4/5 py-2 min-h-screen">
      <div className="flex-1 bg-white p-8 rounded-lg shadow">
        <form onSubmit={handleEditJob}>
          <div className="mb-6 flex justify-center items-center">
            <label htmlFor="companyDetails" className="block mb-2 text-sm font-bold text-gray-900">Edit Job Post Details</label>
          </div>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label htmlFor="job_title" className="block mb-2 text-sm font-medium text-gray-900">Job Title</label>
              <input
                type="text"
                id="job_title"
                name="job_title"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Job Title"
                required
                value={formData.job_title}
                onChange={handleInputChange}
              />
              {errors.job_title && <p className="text-red-500 text-sm">{errors.job_title}</p>}
            </div>
            <div>
              <label htmlFor="location" className="block mb-2 text-sm font-medium text-gray-900">Location</label>
              <input
                id="location"
                name="location"
                value={formData.location}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
              >
                {/* <option value="">Select Location</option>
                {counties.map((county, index) => (
                  <option key={index} value={county}>{county}</option>
                ))} */}
              </input>
              {errors.location && <p className="text-red-500 text-sm">{errors.location}</p>}
            </div>
            <div>
              <label htmlFor="work_type" className="block mb-2 text-sm font-medium text-gray-900">Work Type</label>
              <select
                id="work_type"
                name="work_type"
                value={formData.work_type}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
              >
                <option value="">Select Work Type</option>
                <option value="Full Time">Full Time</option>
                <option value="Part Time">Part Time</option>
                <option value="Contract">Contract</option>
                <option value="Temporary">Temporary</option>
                <option value="Internship">Internship</option>
                <option value="Volunteer">Volunteer</option>
                <option value="Graduate Trainee">Graduate Trainee</option>
              
              </select>
              {errors.work_type && <p className="text-red-500 text-sm">{errors.work_type}</p>}
            </div>
            <div>
              <label htmlFor="deadline_date" className="block mb-2 text-sm font-medium text-gray-900">Deadline Date</label>
              <input
                type="date"
                id="deadline_date"
                name="deadline_date"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
                value={formData.deadline_date}
                onChange={handleInputChange}
              />
              {errors.deadline_date && <p className="text-red-500 text-sm">{errors.deadline_date}</p>}
            </div>
            <div>
              <label htmlFor="job_function" className="block mb-2 text-sm font-medium text-gray-900">Job Function</label>
              <select
                id="job_function"
                name="job_function"
                value={formData.job_function}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option value="">Select Job Function</option>
                {jobFunctionOptions.map((option, index) => (
                  <option key={index} value={option}>{option}</option>
                ))}
              </select>
              {errors.job_function && <p className="text-red-500 text-sm">{errors.job_function}</p>}
            </div>
            <div>
              <label htmlFor="threshold" className="block mb-2 text-sm font-medium text-gray-900">Threshold</label>
              <input
                type="number"
                id="threshold"
                name="threshold"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Threshold"
                value={formData.threshold}
                onChange={handleInputChange}
              />
              {errors.threshold && <p className="text-red-500 text-sm">{errors.threshold}</p>}
            </div>
            <div>
              <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900">Work Model</label>
              <select
                id="experience"
                name="experience"
                value={formData.experience}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option value="">Select work model</option>
                {experienceOptions.map((option, index) => (
                  <option key={index} value={option}>{option}</option>
                ))}
              </select>
              {errors.experience && <p className="text-red-500 text-sm">{errors.experience}</p>}
            </div>
          </div>
          <div className="my-6">
            <label htmlFor="screening_questions" className="block mb-2 text-sm font-medium text-gray-900">Screening Questions</label>
            {formData.screening_questions.map((question, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="text"
                  name={`screening_question_${index}`}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mr-2"
                  placeholder={`Question ${index + 1}`}
                  value={question.question}
                  onChange={(e) => handleQuestionChange(index, e.target.value)}
                />
                <button
                  type="button"
                  onClick={() => removeScreeningQuestion(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={addScreeningQuestion}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
              Add Question
            </button>
          </div>
          <div className="my-6">
            <label htmlFor="required_documents" className="block mb-2 text-sm font-medium text-gray-900">Required Documents</label>
            {requiredDocumentsOptions.map((doc, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="checkbox"
                  id={`required_document_${index}`}
                  name="required_documents"
                  value={doc}
                  checked={formData.required_documents.includes(doc)}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                <label htmlFor={`required_document_${index}`} className="text-gray-900">{doc}</label>
              </div>
            ))}
          </div>
          <div className="my-6">
            <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">Description</label>
            <textarea
              id="description"
              name="description"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              rows="5"
              placeholder="Description"
              required
              value={formData.description}
              onChange={handleInputChange}
            ></textarea>
            {errors.description && <p className="text-red-500 text-sm">{errors.description}</p>}
          </div>
          <div className="mb-6">
            <label htmlFor="qualifications" className="block mb-2 text-sm font-medium text-gray-900">Qualifications</label>
            <textarea
              id="qualifications"
              name="qualification"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              rows="3"
              placeholder="Qualifications"
              required
              value={formData.qualification}
              onChange={handleInputChange}
            ></textarea>
            {errors.qualification && <p className="text-red-500 text-sm">{errors.qualification}</p>}
          </div>
          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
          >
            Update
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditJob;
