import React, { useState, useEffect ,useRef} from 'react';
import { BiDotsVertical } from "react-icons/bi";
import { formatDate } from '../../EmployerDashBoard/Applicants/utils/dateUtils';

const ApplicantsTable = ({ 
  handleCheckboxChange, 
  handleApplicantClick, 
  handleSelectAll, 
  search, 
  onSearchChange, 
  limit, 
  onLimitChange, 
  page, 
  total, 
  onPageChange,
  applicants,
  modalIsOpen
}) => {
  const [dropdownVisible, setDropdownVisible] = useState({});
  const dropdownRef = useRef(null);
  
  

  


   const handleDropdownToggle = (applicantId) => {
    setDropdownVisible(prevState => ({
      ...prevState,
      [applicantId]: !prevState[applicantId],
    }));
   };
  
  
  
  

  
   useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible({});
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
   }, []);  
  
   const totalPages = Math.ceil(total / limit);
  
  return (
    <div className="flex-grow p-6 overflow-auto bg-white">
      
      <div className="overflow-x-auto ">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
          <h1 className="text-2xl font-bold text-green-600 mb-4 md:mb-0">Applicants</h1>
          <div className="flex flex-col md:flex-row items-center">
            
            <select value={limit} onChange={onLimitChange} className="border border-gray-300 p-2 rounded w-full md:w-auto mt-2 md:mt-0 md:ml-2">
              <option value={15}>15</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
     <table className="min-w-full leading-normal table-auto md:table-fixed">
          <thead>
            <tr className="hidden md:table-row">
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                #
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Candidate
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Applied Position
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Stage
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Applied On
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {applicants.map(applicant => (
              <tr key={applicant.id} className="bg-white border-b md:table-row block md:border-none">
                <td className="px-5 py-2 border-b border-gray-200 text-sm md:border-none block md:table-cell" data-label="#">
                  <span className="block md:hidden font-bold text-green-500">#</span>
                  {applicant.id}
                </td>
                <td
                  className={`px-5 py-2 border-b border-gray-200 text-sm md:border-none block md:table-cell`}
                  data-label="Candidate"
                  onClick={() => handleApplicantClick(applicant.id)}
                >
                  <span className="block md:hidden font-bold text-green-500">Candidate</span>
                  <img
                    className="h-10 w-10 rounded-full inline-block"
                    src={applicant.jobseeker_image}
                    alt={`${applicant.jobseeker_firstname} ${applicant.jobseeker_surname}`}
                  />
                  <div className="inline-block ml-2 align-top">
                    <p className="whitespace-no-wrap" style={{ fontWeight: applicant.is_viewed ? 'normal' : 'bold' }}>
                      {applicant.jobseeker_firstname} {applicant.jobseeker_surname}
                    </p>
                    <p className="whitespace-no-wrap" style={{ fontWeight: applicant.is_viewed ? 'normal' : 'bold' }}>
                      {applicant.jobseeker_specialization}
                    </p>
                  </div>
                </td>
                <td
                  className={`px-5 py-2 border-b border-gray-200 text-sm md:border-none block md:table-cell `}
                  data-label="Applied Position"
                  onClick={() => handleApplicantClick(applicant.id)}
                >
                  <span className="block md:hidden font-bold text-green-500">Applied Position</span>
                  <p className="whitespace-no-wrap" style={{ fontWeight: applicant.is_viewed ? 'normal' : 'bold' }}>
                    {applicant.job_post_applied}
                  </p>
                </td>
                <td
                  className={`px-5 py-2 border-b border-gray-200 text-sm md:border-none block md:table-cell ${
                    applicant.is_rejected ? 'text-red-500' : 'text-gray-900'
                  }`}
                  data-label="Applied Position"
                  onClick={() => handleApplicantClick(applicant.id)}
                >
                  <span className="block md:hidden font-bold text-green-500">Applied Position</span>
                  <p className="whitespace-no-wrap" style={{ fontWeight: applicant.is_viewed ? 'normal' : 'bold' }}>
                    {applicant.is_rejected ? "Rejected" : applicant.stage}
                  </p>
                </td>
                <td
                  className={`px-5 py-2 border-b border-gray-200 text-sm md:border-none block md:table-cell `}
                  data-label="Applied On"
                  onClick={() => handleApplicantClick(applicant.id)}
                >
                  <span className="block md:hidden font-bold text-green-500">Applied On</span>
                  <p className="whitespace-no-wrap" style={{ fontWeight: applicant.is_viewed ? 'normal' : 'bold' }}>
                    {formatDate(applicant.applied_date)}
                  </p>
                </td>
                <td className="py-2 px-4">
                  {!modalIsOpen && (
                    <button onClick={() => handleDropdownToggle(applicant.id)} className="focus:outline-none relative z-10">
                      <BiDotsVertical className="text-gray-600" />
                    </button>
                  )}
                  {dropdownVisible[applicant.id] && (
                    <div ref={dropdownRef} className="absolute right-0 mt-2 py-2 w-48 bg-white border rounded-lg shadow-lg z-20">
                      <ul>
                        <li 
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer" 
                          onClick={() => handleApplicantClick(applicant.id)}
                        >
                          View
                        </li>
                      </ul>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between items-center mt-4">
          <select value={limit} onChange={onLimitChange} className="border border-gray-300 p-2 rounded w-full md:w-auto mt-2 md:mt-0 md:ml-2">
              <option value={15}>15</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          <span>{page} of {totalPages}</span>
          <div className=' flex gap-5'>

          <button 
            className="bg-green-600 text-white px-4 py-2 rounded" 
            disabled={page <= 1} 
            onClick={() => onPageChange(page - 1)}
          >
            Previous
          </button>
          <button 
            className="bg-green-600 text-white px-4 py-2 rounded" 
            disabled={page >= totalPages} 
            onClick={() => onPageChange(page + 1)}
          >
            Next
          </button>
          </div>
        </div>
      </div>
      </div>
  );
};

export default ApplicantsTable;
