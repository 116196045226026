import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TiArrowBackOutline } from "react-icons/ti";

const Breadcrumb = ({ back = "/" }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  useEffect(() => {
    const pathname = location.pathname;
    const parts = pathname.split("/").filter((part) => part && isNaN(part));

    const newBreadcrumbItems = parts.map((part, index) => ({
      label: decodeURIComponent(part).replace("-", " "),
      to: `/${parts.slice(0, index + 1).join("/")}`,
    }));

    // Add Home for the root path
    if (newBreadcrumbItems.length === 0) {
      newBreadcrumbItems.push({ label: "Home", to: "/" });
    } else {
      newBreadcrumbItems.unshift({ label: "Home", to: "/" });
    }

    setBreadcrumbItems(newBreadcrumbItems);
  }, [location.pathname]);

  return (
    <div className="p-5 flex bg-white rounded-lg justify-between items-center h-11">
      <div className="flex items-center text-gray-600 font-poppins">
        {breadcrumbItems.map((item, index) => (
          <div key={index} className="flex items-center font-bold">
            {index > 0 && <span className="mx-2 text-xl text-gray-300">{'>'}</span>}
            {item.to ? (
              <Link
                to={item.to}
                className={`text-xl ${
                  index === breadcrumbItems.length - 1
                    ? "text-gray-900"
                    : "text-gray-300"
                } ${index === breadcrumbItems.length - 1 && 'hidden sm:inline-block'}`} // Hide the last item on small screens
              >
                {item.label.charAt(0).toUpperCase() + item.label.slice(1)}
              </Link>
            ) : (
              <span className={`text-xl ${index === breadcrumbItems.length - 1 && 'hidden sm:inline-block'}`}>{item.label}</span> // Hide the last item on small screens
            )}
          </div>
        ))}
      </div>
      <div
        onClick={() => navigate(-1)}
        className="cursor-pointer flex items-center gap-3 rounded-xl border border-gray-500 p-3 h-9"
      >
        <span>
          <TiArrowBackOutline />
        </span>
        <p>Back</p>
      </div>
    </div>
  );
};

export default Breadcrumb;
