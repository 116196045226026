import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAxios from '../hooks/useAxios';
import { useError } from '../hooks/useError';
import { useLoad } from '../hooks/useLoad';
import JobListing from './JobListing'; // Import the JobListing component

const CompanyDetails = ({ user }) => {
  const { companyIdSlug } = useParams();
  const { get } = useAxios();
  const { setError } = useError();
  const { setLoading } = useLoad();
  const [company, setCompany] = useState(null);
  const [jobs, setJobs] = useState([]); // State to store jobs

  const companyId = companyIdSlug.split('-')[0];

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      setLoading(true);
      try {
        const response = await get(`/companies/${companyId}`);
        console.log('Company Details:', response); // Log the response to verify the data
        setCompany(response);
      } catch (error) {
        setError('Error fetching company details');
      } finally {
        setLoading(false);
      }
    };

    const fetchCompanyJobs = async () => {
      try {
        const response = await get(`/companies/${companyId}/jobs`);
        setJobs(response);
      } catch (error) {
        setError('Error fetching company jobs');
      }
    };

    fetchCompanyDetails();
    fetchCompanyJobs();
  }, [get, setError, setLoading, companyId]);

  if (!company) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto mt-0 p-5">
      <div className="relative w-full h-64 bg-gray-100 rounded-t-lg shadow-lg">
        <img src={company.company_logo} alt="Cover" className="w-full h-full object-cover rounded-t-lg mb-4" />
        <div className="absolute bottom-0 left-4 transform translate-y-1/2 p-2 bg-white rounded-full">
          <img src={company.company_logo} alt={company.company_name} className="w-20 h-20 object-contain rounded-full border-2 border-white shadow-lg" />
        </div>
        <div className="absolute bottom-4 left-32 p-2 bg-black bg-opacity-50 text-white rounded-lg">
          <h1 className="text-2xl font-bold">{company.company_name}</h1>
        </div>
      </div>
      <div className="bg-white p-12 rounded-b-lg shadow-lg flex flex-col">
        <div className="flex items-center mb-4">
          <div className="flex-grow grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <p className="text-sm font-semibold text-gray-500">Location</p>
              <p className="text-sm text-gray-800">{company.location}</p>
            </div>
            <div>
              <p className="text-sm font-semibold text-gray-500">Industry</p>
              <p className="text-sm text-gray-800">{company.industry}</p>
            </div>
            <div>
              <p className="text-sm font-semibold text-gray-500">Company size</p>
              <p className="text-sm text-gray-800">{company.number_of_employees}</p>
            </div>
            <div>
              <p className="text-sm font-semibold text-gray-500">Website</p>
              <p className="text-sm text-gray-800">
                <a href={company.website} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                  {company.website}
                </a>
              </p>
            </div>
          </div>
        </div>
        <h2 className="text-xl font-semibold text-gray-800 mb-2">About</h2>
        <p className="text-gray-700">{company.bio}</p>
      </div>
      {jobs.length > 0 && ( // Conditionally render the jobs section
        <div className="bg-white p-5 mt-5 rounded-lg shadow-lg">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Jobs at {company.company_name}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {jobs.map((job) => (
              <JobListing key={job.id} job={job} user={user} /> // Pass user prop to JobListing
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyDetails;
