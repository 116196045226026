import React from 'react'
import { Routes, Route } from 'react-router-dom'
import TaskList from './myTask/TaskList'
import ReviewApplicants from './Applicants/Main'
import Edit from './Mydetails/Edit'

const TeamMemberDashboard = ({user}) => {
  return (
      <div className=''  style={{ minHeight: '75vh' }}>   
      
      {/* <TaskList/> */}
      {/* <Sidebar/> */}
      {/* <ReviewApplicants/> */}
      <Routes>
        
        <Route path="my task" element={<TaskList />} />
        <Route path="Review applicants" element={<ReviewApplicants />} />
        <Route path='Edit my details' element={<Edit/>}/>

      </Routes>
            
    </div>
  )
}
export default TeamMemberDashboard

