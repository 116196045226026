import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { pdfjs } from 'react-pdf';

// Set worker URL
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`;

const PdfViewer = ({ url }) => {
    const proxyUrl = 'https://api.allorigins.win/raw?url=';
    const proxiedUrl = proxyUrl + encodeURIComponent(url);

    return (
        <div className="w-full h-full">
            <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`}>
                <Viewer fileUrl={proxiedUrl} />
            </Worker>
        </div>
    );
};

export default PdfViewer;
