import { useState, createContext, useContext } from 'react';

const ErrorContext = createContext();

export const useError = () => useContext(ErrorContext);

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  const handleError = (error) => {
    let errorMessage = 'An error occurred';
    if (error.response) {
      if (error.response.status >= 500) {
        errorMessage = 'Server error. Please contact the admin.';
      } else {
        errorMessage = error.response.data.message || errorMessage;
      }
    } else if (error.request) {
      errorMessage = 'No response from server. Please try again later.';
    } else {
      errorMessage = error.message;
    }
    setError(errorMessage);
  };

  return (
    <ErrorContext.Provider value={{ error, setError: handleError }}>
      {error && (
        <div className="fixed bottom-4 right-4 bg-red-500 text-white p-4 rounded-lg shadow-lg">
          <p>{error}</p>
          <button onClick={() => setError(null)} className="mt-2 text-sm underline">
            Close
          </button>
        </div>
      )}
      {children}
    </ErrorContext.Provider>
  );
};
