import React, { useEffect } from 'react';

const SimpleSearch = ({ keyword, setKeyword, handleSearch }) => {
  // Trigger search when the keyword changes
  useEffect(() => {
    handleSearch(keyword);
  }, [keyword, handleSearch]);

  return (
    <div className="flex flex-col sm:flex-row items-center mb-4 space-y-2 sm:space-y-0 sm:space-x-2 w-full">
      <input
        type="text"
        className="text-sm w-full sm:flex-1 px-4 py-2 border border-solid border-gray-300 rounded"
        placeholder="Enter a search keyword"
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
      />
    </div>
  );
};

export default SimpleSearch;
