import React, { useState, useEffect, useRef } from 'react';
import { FaBell } from 'react-icons/fa';
import useAxios from '../hooks/useAxios';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useError } from '../hooks/useError';
import { useLoad } from '../hooks/useLoad';

const BATCH_SIZE = 5; // Define the size of each batch
const processAlertsInBatches = async (alerts, patch, setAlerts, setUnreadCount) => {
  const unreadAlerts = alerts.filter(alert => !alert.is_viewed);

  for (let i = 0; i < unreadAlerts.length; i += BATCH_SIZE) {
    const batch = unreadAlerts.slice(i, i + BATCH_SIZE);
    const promises = batch.map(alert => patch(`/alertbyemployer/${alert.id}`, {}, { useAuth: true }));
    await Promise.all(promises);

    const updatedAlerts = alerts.map(alert => ({
      ...alert,
      is_viewed: unreadAlerts.find(unreadAlert => unreadAlert.id === alert.id) ? true : alert.is_viewed,
    }));

    setAlerts(updatedAlerts);
    setUnreadCount(updatedAlerts.filter(alert => !alert.is_viewed).length);
  }
};

const AlertPopup = ({ isOpen, onClose, alerts, setAlerts, setUnreadCount }) => {
  const { patch } = useAxios();
  const { setError } = useError();
  const prevIsOpen = useRef(isOpen);

  useEffect(() => {
    prevIsOpen.current = isOpen;
  }, [isOpen]);

  const markAlertsAsViewed = async () => {
    try {
      await processAlertsInBatches(alerts, patch, setAlerts, setUnreadCount);
    } catch (error) {
      setError(error.message);
    }
  };

  if (!isOpen) return null;

  const unreadCount = alerts.filter(alert => !alert.is_viewed).length;

  return (
    <div className="fixed inset-0 z-50 flex justify-end bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-4 shadow-lg relative m-4 lg:w-2/4 flex flex-col cursor-pointer">
        <XMarkIcon className="h-10 w-10 absolute top-2 right-2" onClick={() => { onClose(); markAlertsAsViewed(); }} />
        <h2 className="text-xl font-semibold mb-4">Alerts ({unreadCount} unread)</h2>
        <div className="flex-1 overflow-y-auto">
          <ul>
            {alerts.map(alert => (
              <li key={alert.id} className={`mb-5 ${alert.is_viewed ? 'bg-white' : 'bg-green-100'}`} >
                <div>
                  <h5 className='font-semibold'>{alert.alert_type} for {alert.job_title}</h5>
                  <div className='pl-5'>
                    <p>{alert.message}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AlertPopup;
