import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';
import { FaBell, FaUserCircle } from 'react-icons/fa';
import useAxios from '../hooks/useAxios';
import AlertPopup from '../EmployerDashBoard/AlertPopup';
import JobseekerAlertPopup from '../JobseekerDashBoard/components/JobseekerAlertPopup';
import ProfilePopup from '../EmployerDashBoard/components/Profile';
import logo from '../assets/images/hireke HD black.png';
import AlertTeamMember from '../TeamMember/AlertTeamMember';
import AdminAlertPopup from '../Admin/Admins/AdminAlertPopup';

const Navbar = ({ user, setUser }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [employerAlerts, setEmployerAlerts] = useState([]);
  const [teamMemberAlerts, setTeamMemberAlerts] = useState([]);
  const [adminAlerts, setAdminAlerts] = useState([]);
  const [jobseekerAlerts, setJobseekerAlerts] = useState({ unread_alerts: [], read_alerts: [] });
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const profileIconRef = useRef(null);
  const profilePopupRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { get } = useAxios();
  const [employerUnreadCount, setEmployerUnreadCount] = useState(0);
  const [teamMemberUnreadCount, setTeamMemberUnreadCount] = useState(0);
  const [adminUnreadCount, setAdminUnreadCount] = useState(0);  
  const [jobseekerUnreadCount, setJobseekerUnreadCount] = useState(0);
  const [isAnalyticsDropdownOpen, setIsAnalyticsDropdownOpen] = useState(false);

  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
    setUser(null);
    navigate('/');
    window.location.reload();
  };

  useEffect(() => {
    const fetchEmployerAlerts = async () => {
      try {
        const data = await get('/alertbyemployer', { useAuth: true });
        setEmployerAlerts(data);

        let count = 0;
        for (const alert of data) {
          if (!alert.is_viewed) {
            count++;
          }
        }
        setEmployerUnreadCount(count);
      } catch (error) {
        console.error('Error fetching employer alerts:', error);
      }
    };
    const fetchTeamMemberAlerts = async () => {
      try {
        const data = await get('/alert/team_member', { useAuth: true });
        setTeamMemberAlerts(data);

        let count = 0;
        for (const alert of data) {
          if (!alert.is_viewed) {
            count++;
          }
        }
        setTeamMemberUnreadCount(count);
      } catch (error) {
        console.error('Error fetching employer alerts:', error);
      }
    };
    const fetchJobseekerAlerts = async () => {
      try {
        const data = await get('/allalerts', { useAuth: true });
        setJobseekerAlerts(data);

        let count = 0;
        for (const alert of data.unread_alerts) {
          if (!alert.is_viewed) {
            count++;
          }
        }
        setJobseekerUnreadCount(count);
      } catch (error) {
        console.error('Error fetching jobseeker alerts:', error);
      }
    };
     const fetchAdminAlerts = async () => {
      try {
        const data = await get('/alert/admin', { useAuth: true });
        setAdminAlerts(data);

        let count = 0;
        for (const alert of data.unread_alerts) {
          if (!alert.is_viewed) {
            count++;
          }
        }
        setAdminUnreadCount(count);
      } catch (error) {
        console.error('Error fetching jobseeker alerts:', error);
      }
    };

    if (user) {
      if (user.role === 'employer') {
        fetchEmployerAlerts();
      } else if (user.role === 'jobseeker') {
        fetchJobseekerAlerts();
      } else if (user.role === 'team member') {
        fetchTeamMemberAlerts()
      } else if (user.role === 'admin') {
        fetchAdminAlerts()
      }
    }
  }, [get, user]);

   useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileIconRef.current && !profileIconRef.current.contains(event.target)) {
        // setIsProfileOpen(false);
        // setIsAnalyticsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const isActive = (path) => location.pathname === path;

  const linkBaseClass = "font-semibold md:hover:bg-green-500 p-2 rounded-md transition-colors duration-300 my-7 md:my-0 md:ml-8";
  const activeClass = "text-green-500";

  const handleEmployerBellClick = () => {
    setIsAlertOpen(true);
    setEmployerUnreadCount(0); // Reset unread count for employers when bell is clicked
  };

  return (
    <>
      <nav className='w-full fixed top-0 left-0 gap-4 z-50 max-h-24'>
        <div className=' lg:flex justify-between items-center bg-white md:px-10 py-4 px-7'>
          <div className='flex items-center gap-2 cursor-pointer text-2xl'>
            <img src={logo} alt='hireke' className='lg:w-full h-10 ' />
          </div>    
          <div onClick={() => setIsOpen(!isOpen)} className='absolute right-8 top-6 cursor-pointer w-7 h-7 lg:hidden '>
            {isOpen ? <XMarkIcon /> : <Bars3Icon />}
          </div>
          <ul className={`lg:flex lg:items-center lg:pb-0 pb-12 absolute lg:static lg:z-auto z-[-1] left-0 w-full 
            lg:w-auto lg:pl-0 pl-9 transition-all bg-white duration-500 ease-in ${isOpen ? 'top-20' : 'top-[-445px]'}`}>
            {!user ? (
              <>
                <li><Link to='/'onClick={() => setIsOpen(!isOpen)}  className={`${linkBaseClass} ${isActive('/') ? activeClass : ''}`}>Home</Link></li>
                <li><Link to='/findjobs' onClick={() => setIsOpen(!isOpen)} className={`${linkBaseClass} ${isActive('/findjobs') ? activeClass : ''}`}>Find Job</Link></li>
                <li><Link to='/Insights' onClick={() => setIsOpen(!isOpen)}  className={`${linkBaseClass} ${isActive('/Insights') ? activeClass : ''}`}>Insights</Link></li>
                <Link to='/login' onClick={() => setIsOpen(!isOpen)}  className={`btn bg-green-500 py-1 px-3 md:ml-8 rounded-md transition-colors duration-300`}>Login</Link>
                <button className='btn py-1 px-3 md:ml-8 rounded md:static' onClick={() => {navigate('/signup'); setIsOpen(!isOpen);}}>Register</button>
              </>
            ) : (
              <>
                {user.role === 'jobseeker' && (
                  <>
                    <li><Link to='/' onClick={() => setIsOpen(!isOpen)} className={`${linkBaseClass} ${isActive('/') ? activeClass : ''} md:mx-4`}>Home</Link></li>
                    <li><Link to='/findjobs'onClick={() => setIsOpen(!isOpen)}  className={`${linkBaseClass} ${isActive('/findjobs') ? activeClass : ''} md:mx-4`}>Find Job</Link></li>
                    <li><Link to='/mydocuments' onClick={() => setIsOpen(!isOpen)}  className={`${linkBaseClass} ${isActive('/mydocuments') ? activeClass : ''} md:mx-4`}>My Documents</Link></li>
                    <li><Link to='/savedjobs' onClick={() => setIsOpen(!isOpen)}  className={`${linkBaseClass} ${isActive('/savedjobs') ? activeClass : ''} md:mx-4`}>Saved Jobs</Link></li>
                    <li><Link to='/appliedjobs' className={`${linkBaseClass} ${isActive('/appliedjobs') ? activeClass : ''} md:mx-4`}>Applied Jobs</Link></li>
                    <li><Link to='/Insights' onClick={() => setIsOpen(!isOpen)}  className={`${linkBaseClass} ${isActive('/Insights') ? activeClass : ''} md:mx-4`}>Insights</Link></li>
                    <div className="relative inline-block mr-4 mt-2">
                      <FaBell onClick={() => {setIsAlertOpen(true);setIsOpen(!isOpen)}} className='text-black-500 cursor-pointer w-7 h-7 hover:bg-green-400' />
                      <span className="absolute top-0 right-0 inline-flex items-center justify-center w-4 h-4 bg-red-500 text-xs font-bold rounded-full transform translate-x-1/2 -translate-y-1/2">{jobseekerUnreadCount}</span>
                    </div>
                    <div
                      ref={profileIconRef}
                      className='font-semibold md:hover:bg-green-500  rounded-md transition-colors duration-300 my-7 md:my-0 md:ml-8 p-2 bg-white'
                      onClick={() => {setIsProfileOpen(!isProfileOpen);setIsOpen(!isOpen)}}
                    >
                      {user.image ? <img src={user.image} alt='profile' className='w-10 h-10 rounded-full' /> : <FaUserCircle className='w-10 h-10' />}
                    </div>
                  </>
                  )}
                  {user.role === 'team member' && (
                    <>
                      <li><Link to='/' onClick={() => setIsOpen(!isOpen)}  className={`${linkBaseClass} ${isActive('/') ? activeClass : ''} md:mx-4`}>Home</Link></li>
                      <li><Link to='/team member/my task' onClick={() => setIsOpen(!isOpen)}  className={`${linkBaseClass} ${isActive('/team member/my task') ? activeClass : ''} md:mx-4`}>My Task</Link></li>
                      
                      <li><Link to='/team member/Review applicants' onClick={() => setIsOpen(!isOpen)} className={`${linkBaseClass} ${isActive('/team member/Review applicants') ? activeClass : ''} md:mx-4`}>Review Applicants</Link></li>
                      <div className="relative inline-block mr-4 mt-2">
                        <FaBell onClick={() => {setIsAlertOpen(true);setIsOpen(!isOpen) }} className='text-black-500 cursor-pointer w-7 h-7 hover:bg-green-400' />
                        <span className="absolute top-0 right-0 inline-flex items-center justify-center w-4 h-4 bg-red-500 text-xs font-bold rounded-full transform translate-x-1/2 -translate-y-1/2">{teamMemberUnreadCount}</span>
                      </div>
                      <div
                        ref={profileIconRef}
                        className='font-semibold md:hover:bg-green-500 p-2 rounded-md transition-colors duration-300 my-7 md:my-0 md:ml-8'
                        onClick={() => { setIsProfileOpen(!isProfileOpen); setIsOpen(!isOpen) }}
                      >
                        {user.image ? <img src={user.image} alt='profile' className='w-10 h-10 rounded-full' /> : <FaUserCircle className='w-10 h-10' />}
                      </div>
                    </>
                  )}

                {user.role === 'employer' && (
                  <>
                    <li><Link to='/myjobs' onClick={() => setIsOpen(!isOpen)} className={`${linkBaseClass} ${isActive('/myjobs') ? activeClass : ''}`}>My Jobs</Link></li>
                    <li><Link to='/applicants' onClick={() => setIsOpen(!isOpen)}  className={`${linkBaseClass} ${isActive('/applicants') ? activeClass : ''}`}>Applicants</Link></li>
                    <li><Link to='/myteam' onClick={() => setIsOpen(!isOpen)}  className={`${linkBaseClass} ${isActive('/myteam') ? activeClass : ''}`}>My Team</Link></li>
                      <li><Link to='/postjob/new' onClick={() => setIsOpen(!isOpen)} className={`${linkBaseClass} ${isActive('/postjob/new') ? activeClass : ''}`}>Post Job</Link></li>

                      <li><Link to='/wallet' onClick={() => setIsOpen(!isOpen)} className={`${linkBaseClass} ${isActive('/wallet') ? activeClass : ''}`}>Wallet</Link></li>
                    <li><Link to='/Insights' onClick={() => setIsOpen(!isOpen)}  className={`${linkBaseClass} ${isActive('/statistics') ? activeClass : ''}`}>Insights</Link></li>
                    <li>
                      <div className="relative inline-block font-semibold md:hover:bg-green-500 p-2 rounded-md transition-colors duration-300  md:my-0 md:ml-8 mr-4 mt-2">
                        <FaBell onClick={()=>{handleEmployerBellClick();setIsOpen(!isOpen)}} className='text-black-500 cursor-pointer w-7 h-7 hover:bg-green-400' />
                        <span className="absolute top-0 right-0 inline-flex items-center justify-center w-4 h-4 bg-red-500 text-xs font-bold rounded-full transform translate-x-1/2 -translate-y-1/2">{employerUnreadCount}</span>
                      </div>
                    </li>
                    <li>
                      <div
                        ref={profileIconRef}
                        className='font-semibold md:hover:bg-green-500 p-2 rounded-md transition-colors duration-300  md:my-0 md:ml-8 bg-white'
                        onClick={() => {setIsProfileOpen(!isProfileOpen);setIsOpen(!isOpen)}}
                      >
                        {user.image ? <img src={user.image} alt='profile' className='w-10 h-10 rounded-full' /> : <FaUserCircle className='w-10 h-10' />}
                      </div>
                    </li>
                  </>
                )}
                {user.role === 'admin' && (
                  <>
                      <li><Link to='/' onClick={() => setIsOpen(!isOpen)} className={`${linkBaseClass} ${isActive('/') ? activeClass : ''}`}>Home</Link></li>
                      <li><Link to='/admin/transaction-history' onClick={() => setIsOpen(!isOpen)} className={`${linkBaseClass} ${isActive('/admin/transaction-history') ? activeClass : ''}`}>Transaction</Link></li>
                    {(user.special_role === 'admin' || user.special_role === 'super admin') && (<li><Link to='/admin' onClick={() => setIsOpen(!isOpen)}  className={`${linkBaseClass} ${isActive('/admin') ? activeClass : ''}`}>Admin</Link></li>)}
                    <li><Link to='/admin/blog'  onClick={() => setIsOpen(!isOpen)} className={`${linkBaseClass} ${isActive('/admin/blog') ? activeClass : ''}`}>Insights</Link></li>
                    {(user.special_role === 'admin' || user.special_role === 'super admin') && (<li><Link to='/admin/reports' onClick={() => setIsOpen(!isOpen)}  className={`${linkBaseClass} ${isActive('/admin/reports') ? activeClass : ''}`}>Reports</Link></li>)}
                    <div className="relative"
                        onMouseOver={() => setIsAnalyticsDropdownOpen(true)}
                        onMouseLeave={() => setIsAnalyticsDropdownOpen(false)}>
                      <div className={`${linkBaseClass} ${isActive('/admin/analytics') ? activeClass : ''} cursor-pointer`}>
                        Analytics
                      </div>
                      {isAnalyticsDropdownOpen && (
                        <div className="absolute left-0 bg-white shadow-md mt-1 rounded-md p-3">
                          <Link to='/admin/analytics' onClick={() => setIsOpen(!isOpen)}  className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-500">Analytics</Link>
                          {(user.special_role === 'admin' || user.special_role === 'super admin') && (
                            <>
                              <Link to="/admin/analytics/employers" onClick={() => setIsOpen(!isOpen)}  className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-500">Employer Analytics</Link>
                                <Link to="/admin/analytics/jobseekers" onClick={() => setIsOpen(!isOpen)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-500">Jobseeker Analytics</Link>
                                <Link to="/admin/analytics/transation" onClick={() => setIsOpen(!isOpen)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-500">Transaction Analytics</Link>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    <li>
                      <div className="relative flex font-semibold md:hover:bg-green-500 p-2 rounded-md transition-colors duration-300 my-7 md:my-0 md:ml-8">
                          <FaBell onClick={() => { handleEmployerBellClick(); setIsOpen(!isOpen) }} className='text-black-500 cursor-pointer w-7 h-7 hover:bg-green-400' />
                        <span className="absolute top-0 right-0 inline-flex items-center justify-center w-4 h-4 bg-red-500 text-xs font-bold rounded-full">{employerUnreadCount}</span>
                      </div>
                    </li>
                    <li>
                      <div
                        ref={profileIconRef}
                        className='font-semibold md:hover:bg-green-500 p-2 rounded-md transition-colors duration-300 my-7 md:my-0 md:ml-8 bg-red-500'
                          onClick={() => { setIsProfileOpen(!isProfileOpen); setIsOpen(!isOpen)}}
                      >
                        {user.image ? <img src={user.image} alt='profile' className='w-10 h-10 rounded-full' /> : <FaUserCircle className='w-10 h-10' />}
                      </div>
                    </li>
                  </>
                )}
              </>
            )}
          </ul>
        </div>
       
      </nav>
      {user?.role === 'jobseeker' && (
        <JobseekerAlertPopup
          isOpen={isAlertOpen}
          onClose={() => setIsAlertOpen(false)}
          alerts={jobseekerAlerts}
          setAlerts={setJobseekerAlerts}
          setUnreadCount={setJobseekerUnreadCount}
        />
      )}
      {user?.role === 'employer' && (
        <AlertPopup
          isOpen={isAlertOpen}
          onClose={() => setIsAlertOpen(false)}
          alerts={employerAlerts}
          setAlerts={setEmployerAlerts}
          setUnreadCount={setEmployerUnreadCount}
        />
      )}
      {user?.role === 'admin' && (
        <AdminAlertPopup
          isOpen={isAlertOpen}
          onClose={() => setIsAlertOpen(false)}
          alerts={adminAlerts}
          setAlerts={setAdminAlerts}
          setUnreadCount={setAdminUnreadCount}
        />
      )}
      {user?.role === 'team member' && (
        <AlertTeamMember
          isOpen={isAlertOpen}
          onClose={() => setIsAlertOpen(false)}
          alerts={teamMemberAlerts}
          setAlerts={setTeamMemberAlerts}
          setUnreadCount={setTeamMemberUnreadCount}
        />
      )}
      <ProfilePopup
        isOpen={isProfileOpen}
        onClose={() => setIsProfileOpen(false)}
        user={user}
        logout={logout}
        popupRef={profilePopupRef}
      />
    </>
  );
};

export default Navbar;
