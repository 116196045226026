import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

const SignUp = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center min-h-screen bg-green-100">
      <div className="absolute top-4 left-4">
        <FaArrowLeft className="text-2xl cursor-pointer" onClick={() => navigate(-1)} />
      </div>
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md relative">
        <h1 className="text-2xl font-bold text-center mb-6">Register as?</h1>
        <div className="flex flex-col space-y-4">
          <button className="bg-green-500 text-white py-2 rounded hover:bg-green-600 transition duration-300" onClick={() => navigate('/signup/jobseeker')}>
            Jobseeker
          </button>
          <button className="bg-green-500 text-white py-2 rounded hover:bg-green-600 transition duration-300" onClick={() => navigate('/signup/employer')}>
            Employer
          </button>
        </div>
        <p className="text-center mt-6">
          Already have an account? <Link to="/login" className="text-blue-500 hover:underline">Login</Link>
        </p>
      </div>
    </div>
  );
};

export default SignUp;
