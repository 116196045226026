import React, { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import useAxios from '../hooks/useAxios';
import PdfViewer from '../components/PdfViewer';

const GreenWarningIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="red" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z" />
  </svg>
);

const JobseekerDetailProfile = ({ onClose }) => {
  const [profile, setProfile] = useState(null);
  const [cv, setCv] = useState(null);
  const { get } = useAxios();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await get('/checksession', { useAuth: true });
        setProfile(data);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    const fetchDocuments = async () => {
      try {
        const documents = await get('/latest-documents', { useAuth: true });
        const cvDocument = documents.find(doc => doc.document_type === 'Cv');
        setCv(cvDocument);
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    };

    fetchProfile();
    fetchDocuments();
  }, [get]);

  if (!profile) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex justify-center items-start pt-0 overflow-y-auto bg-gray-100 min-h-screen">
      <div className="bg-white rounded-lg p-4 shadow-lg relative mx-4 my-4 w-full max-w-4xl sm:mx-8">
        {onClose && <XMarkIcon className="h-6 w-6 absolute top-4 right-4 cursor-pointer" onClick={onClose} />}
        <div className="mb-4 text-center flex items-center justify-center">
          <GreenWarningIcon />
          <p className="text-green-500 font-semibold ml-2">Keep your profile and CV updated for each job application.</p>
        </div>
        <div className="flex flex-col sm:flex-row items-center mb-4">
          <img src={profile.image} alt="Profile" className="w-24 h-24 rounded-full mb-4 sm:mb-0 sm:mr-4" />
          <div className="text-center sm:text-left">
            <h1 className="text-2xl font-bold">{`${profile.first_name} ${profile.other_names} ${profile.surname}`}</h1>
            <p className="text-left"><strong>Contacts:</strong> {profile.phone_number}</p>
            <p className="text-left"><strong>Email:</strong> {profile.email}</p>
            <p className="text-left"><strong>Location:</strong> {profile.location}</p>
          </div>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold">Bio</h2>
          <p>{profile.bio}</p>
        </div>
        <div className="mb-4 w-full">
          <h2 className="text-xl font-semibold">CV</h2>
          {cv && cv.document_file ? (
            <div className="w-full h-auto">
              <PdfViewer url={cv.document_file} />
            </div>
          ) : (
            <p>No CV available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobseekerDetailProfile;
