import React, { useState, useEffect, useRef } from 'react';
import useAxios from '../../hooks/useAxios';
import { useLoad } from '../../hooks/useLoad';
import { useError } from '../../hooks/useError';
import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';

import Card from '../../Admin/components/Card'



const Edit = () => {
    
  const inputRef = useRef(null);
  const { get, patch, post } = useAxios(); 
  const { setLoading } = useLoad();
  const { setError } = useError();
  const navigate = useNavigate();
  const inputs = useRef([]);
  const [formData, setFormData] = useState({
    username:'',
    email: '',
});
    const [initialData, setInitialData] = useState({});
    const [image, setImage] = useState(null);
    const [errors, setErrors] = useState({});
    const [otpSent, setOtpSent] = useState(false);
    const [otpSet, setOtp] = useState(['', '', '', '', '', '']);
    const [otpValidate, setOtpValidate] = useState('');

    const fetchUser = async () => {
    try {
      const data = await get('/checksession', { useAuth: true });
      setFormData({
        id: data.id,
        username: data.username,
        email: data.email,
      });
      setInitialData({
        id: data.id,
        username: data.username,
        email: data.email,
      }); 
    } catch (error) {
      setError(error);
    }
    };
    
    useEffect(() => {
    fetchUser();
    }, []);
    
    const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
    };

    const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    setFormData({
      ...formData,
      image: file,
    });
  };

  const handleImageClick = () => {
    inputRef.current.click();
  };
    
    const validate = () => {
    const newErrors = {};
    if (!formData.username) newErrors.first_name = 'username is required';
    if (!formData.email) newErrors.email = 'email is required';
    if (!otpSet || otpSet.length < 6) newErrors.otpSet = '*Required';
    return newErrors;
  };
    
    const convertToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        formData.append(key, JSON.stringify(data[key]));
      } else {
        formData.append(key, data[key]);
      }
    });
    if (image) {
      formData.append('image', image);
    }
    return formData;
  };

    const handleSendOtp = async () => {
    try {
      setLoading(true);
        const response = await post(`/sentteamotp`, {},{ useAuth: true });
      if (response) {
        setLoading(false);
        setOtpSent(true); // Show OTP form
        console.log(response.otp)
        setOtpValidate(response.otp)
        toast.success(response.message);
      } else {
        setLoading(false);
        toast.error('Failed to send OTP');
      }
    } catch (error) {
      setLoading(false);
      toast.error('Error sending OTP');
    }
    };
    
    const handleChangeOtp = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]*$/.test(value) && value.length <= 1) {
      const newOtp = [...otpSet];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < 5) {
        inputs.current[index + 1].focus();
      }
    }
    };

    const handleSubmitForm = async () => {
    try {
      const otp = otpSet.join('');
      const changedData = getChangedData(); // Get only changed data
      const formDataObj = convertToFormData({ ...changedData, otp });
      const validationErrors = validateOtp();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else if (otp === otpValidate) {
        const response = await patch(`/teamupdate`, formDataObj, {
          useAuth: true
        });
        if (response) {
          setLoading(false);
          toast.success('Employer details updated successfully');
          navigate("/team member/my task");
        } else {
          setLoading(false);
          toast.error(response);
        }
      } else {
        setErrors({ otpSet: 'Invalid OTP' });
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      toast.error('Failed to update employer details');
    }
    };
    
    const validateOtp = () => {
    const newErrors = {};
    if (!otpSet.every((digit) => digit)) {
      newErrors.otpSet = 'Please enter OTP';
    }
    return newErrors;
  };
    
    const getChangedData = () => {
    const changedData = {};
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== initialData[key]) {
        changedData[key] = formData[key];
      }
    });
    return changedData;
    };

    const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    }else {
      handleSendOtp();
    }
    };
    
     const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otpSet[index] && index > 0) {
      inputs.current[index - 1].focus();
    }
    }; 
    
    
    return (
      <>
    <Card title="Edit my Details">
      <div className="p-6">
        
              <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
              <label className="block text-gray-700">Profile</label>
              <div onClick={handleImageClick} className="bg-[#fff] h-[100px] w-[100px] my-4 rounded-[5px] cursor-pointer">
                {image ? (
                  <img className="w-full h-full object-cover" src={URL.createObjectURL(image)} alt="Company Logo" />
                ) : (
                  <img className="w-full h-full object-cover" src="https://iili.io/JVksC6Q.png" alt="Placeholder Logo" />
                )}
              </div>
              <input type="file" ref={inputRef} onChange={handleImageChange} className="hidden" />
            </div>
          
          <div>
            <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">UserName</label>
            <input
              type="text"
              name="last_name"
              value={formData.username}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            
          </div>
          <button
            type="submit"
            className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Edit detail
          </button>
        </form>
      </div>
            </Card>
            {otpSent && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <div className='flex-1 pb-4 space-x-2'>
                <h3 className="text-xl mb-4">Enter OTP</h3>
                {otpSet.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    value={digit}
                    onChange={(e) => handleChangeOtp(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(el) => (inputs.current[index] = el)}
                    className={`w-12 h-12 text-center rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.otpSet ? 'border-red-500' : 'border-gray-300'}`}
                    maxLength={1}
                  />
                ))}
                {errors.otpSet && <p className="text-red-500 text-sm">{errors.otpSet}</p>}
              </div>
              <button
                onClick={handleSubmitForm}
                className="bg-blue-500 text-white py-2 px-4 rounded"
              >
                Verify OTP
              </button>
            </div>
          </div>
        )}
            </>
  )
}

export default Edit