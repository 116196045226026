import React, { useState, useEffect } from 'react';
import useAxios from '../hooks/useAxios';
import { useError } from '../hooks/useError';
import { useLoad } from '../hooks/useLoad';
import { ArrowDownTrayIcon, CheckCircleIcon } from '@heroicons/react/24/solid';

const ApplyJobModal = ({ isOpen, onClose, jobId }) => {
  const { get, post } = useAxios();
  const { setError } = useError();
  const { loading, setLoading } = useLoad();
  const [job, setJob] = useState(null);
  const [user, setUser] = useState(null);
  const [latestDocuments, setLatestDocuments] = useState({});
  const [selectedDocuments, setSelectedDocuments] = useState({});
  const [documentLoading, setDocumentLoading] = useState({});
  const [answers, setAnswers] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    surname: '',
    email: '',
    phone_number: '',
  });
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!isOpen) return;

    const fetchDetails = async () => {
      setLoading(true);
      try {
        const jobData = await get(`/job-application-details/${jobId}`, { useAuth: true });
        setJob(jobData);

        const userData = await get('/checksession', { useAuth: true });
        setUser(userData);
        setFormData({
          first_name: userData.first_name,
          surname: userData.surname,
          email: userData.email,
          phone_number: userData.phone_number,
        });

        const latestDocumentsData = await get('/latest-documents', { useAuth: true });
        const documentsByType = latestDocumentsData.reduce((acc, doc) => {
          acc[doc.document_type] = doc;
          return acc;
        }, {});
        setLatestDocuments(documentsByType);
        setSelectedDocuments(documentsByType);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [isOpen, jobId, get, setError, setLoading]);

  useEffect(() => {
    if (!isOpen) {
      setIsSubmitted(false); // Reset isSubmitted when modal is closed
    }
  }, [isOpen]);

  const handleChange = (e, questionId) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: e.target.value,
    }));
  };

  const handleFileChange = async (e, documentType) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('document_name', file.name);
    formData.append('document_type', documentType);

    setDocumentLoading((prevState) => ({
      ...prevState,
      [documentType]: true,
    }));
    setIsUploading(true);

    try {
      const response = await post('/save_url', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      });
      setLatestDocuments({
        ...latestDocuments,
        [documentType]: response.data.document,
      });
      setSelectedDocuments({
        ...selectedDocuments,
        [documentType]: response.data.document,
      });
    } catch (error) {
      setError(error);
    } finally {
      setDocumentLoading((prevState) => ({
        ...prevState,
        [documentType]: false,
      }));
      setIsUploading(false);
    }
  };

  const handleApply = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const handleConfirm = async () => {
    const documents = Object.keys(selectedDocuments).map((type) => ({
      document_id: selectedDocuments[type].id, // Include document ID if available
      document_file: selectedDocuments[type].document_file,
      document_name: selectedDocuments[type].document_name,
      document_type: type,
    }));

    const applicationData = {
      answers,
      documents
    };

    try {
      setIsSubmitting(true);
      await post(`/apply-job/${jobId}`, applicationData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      });
      setIsSubmitted(true);
      setShowConfirmation(false);
    } catch (error) {
      setError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBack = () => {
    setShowConfirmation(false);
  };

  const handleClose = () => {
    // Reset state
    setJob(null);
    setUser(null);
    setLatestDocuments({});
    setSelectedDocuments({});
    setDocumentLoading({});
    setAnswers({});
    setIsSubmitted(false);
    setShowConfirmation(false);
    setFormData({
      first_name: '',
      surname: '',
      email: '',
      phone_number: '',
    });
    onClose();
  };

  if (!isOpen) return null;

  if (!job || !user) {
    return <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">Loading...</div>;
  }

  if (isSubmitted) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg text-center w-11/12 max-w-lg my-8">
          <p className="text-2xl font-semibold text-green-600">Your application has been submitted successfully!</p>
          <button
            className="mt-4 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50 py-4 px-4">
      <div className={`bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-4xl overflow-auto max-h-full relative my-8 ${isSubmitting ? 'opacity-50' : ''}`}>
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
          onClick={handleClose}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        {job.applied && (
          <div className="absolute top-0 right-10 bg-white-100 p-2 rounded-bl-lg flex items-center">
            <svg className="w-6 h-6 text-green-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
            </svg>
            <div className="flex flex-col items-start">
              <p className="text-green-800 font-semibold">
                Application submitted on {new Date(job.application_date).toLocaleDateString()}
              </p>
            </div>
          </div>
        )}
        <div className="flex flex-col sm:flex-row items-center mb-6">
          <img src={user.image} alt="Profile" className="w-16 h-16 rounded-full mr-4" />
          <div>
            <h2 className="text-2xl font-bold text-green-600">{`${user.first_name} ${user.surname}`}</h2>
            <p className="text-gray-600">{user.bio}</p>
            <p className="text-sm text-gray-500">{user.location}</p>
          </div>
        </div>
        <form onSubmit={handleApply} className="mt-6 space-y-4">
          <div>
            <h3 className="text-lg font-semibold text-gray-700">Your Details:</h3>
            <div className="mt-2 space-y-4">
              <div>
                <label className="block text-gray-700">First Name:</label>
                <input
                  type="text"
                  name="first_name"
                  className="mt-1 p-2 border rounded w-full"
                  value={formData.first_name}
                  readOnly
                />
              </div>
              <div>
                <label className="block text-gray-700">Last Name:</label>
                <input
                  type="text"
                  name="surname"
                  className="mt-1 p-2 border rounded w-full"
                  value={formData.surname}
                  readOnly
                />
              </div>
              <div>
                <label className="block text-gray-700">Email:</label>
                <input
                  type="email"
                  name="email"
                  className="mt-1 p-2 border rounded w-full"
                  value={formData.email}
                  readOnly
                />
              </div>
              <div>
                <label className="block text-gray-700">Phone Number:</label>
                <input
                  type="text"
                  name="phone_number"
                  className="mt-1 p-2 border rounded w-full"
                  value={formData.phone_number}
                  readOnly
                />
              </div>
            </div>
          </div>
          {job.screening_questions && job.screening_questions.length > 0 && (
            <div>
              <h3 className="text-lg font-semibold text-gray-700">Screening Questions:</h3>
              <ul className="list-disc list-inside space-y-2 mt-2">
                {job.screening_questions.map((question) => (
                  <li key={question.id} className="text-gray-700">
                    {question.question_text}
                    <input
                      type="text"
                      className="mt-2 p-2 border rounded w-full"
                      onChange={(e) => handleChange(e, question.id)}
                      required
                      value={answers[question.id] || ''}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
          {job.required_documents.map((docType) => (
            <div key={docType}>
              <h3 className="text-lg font-semibold text-gray-700">{docType === 'Cv' ? 'Resume' : docType}:</h3>
              <p className="text-sm text-gray-600 mb-2">Be sure to include an updated {docType.toLowerCase()} *</p>
              {(latestDocuments[docType] || documentLoading[docType]) && (
                <div className={`flex items-center border p-2 rounded ${selectedDocuments[docType] && selectedDocuments[docType].id === latestDocuments[docType]?.id ? 'border-green-600' : 'border-gray-300'}`}>
                  {documentLoading[docType] ? (
                    <div className="flex items-center justify-center w-full h-full">
                      <svg className="animate-spin h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l2.121 2.122A8 8 0 004 12H0c0 2.021.678 3.884 1.809 5.291z"></path>
                      </svg>
                    </div>
                  ) : (
                    <>
                      <div className="flex items-center space-x-2">
                        <div className="bg-green-400 text-white p-1 rounded">PDF</div>
                        <div>
                          <p className="text-sm font-medium">{latestDocuments[docType].document_name}</p>
                          <p className="text-xs text-gray-500">Uploaded on {new Date(latestDocuments[docType].upload_date).toLocaleDateString()}</p>
                        </div>
                      </div>
                      <a href={latestDocuments[docType].document_file} download className="ml-auto text-blue-600 hover:underline">
                        <ArrowDownTrayIcon className="h-5 w-5" />
                      </a>
                      <CheckCircleIcon
                        className={`h-5 w-5 ml-2 cursor-pointer ${selectedDocuments[docType] && selectedDocuments[docType].id === latestDocuments[docType].id ? 'text-green-500' : 'text-gray-500'}`}
                        onClick={() => setSelectedDocuments({ ...selectedDocuments, [docType]: latestDocuments[docType] })}
                      />
                    </>
                  )}
                </div>
              )}
              <label className="block mt-4">
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, docType)}
                  className="hidden"
                  accept=".doc, .docx, .pdf"
                />
                <div className="cursor-pointer px-4 py-2 border rounded text-blue-600 border-blue-600 text-center">
                  Update {docType.toLowerCase()}
                </div>
              </label>
            </div>
          ))}
          <p className="text-sm text-gray-500 mt-4 text-center">
            By submitting this application, you agree to our terms & services.
          </p>
          <div className="flex justify-between">
            <button
              type="button"
              onClick={handleClose}
              className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
            >
              Submit
            </button>
          </div>
        </form>
        {showConfirmation && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full my-8">
              <h3 className="text-lg font-semibold text-gray-700 mb-4">Review Your Application</h3>
              <p className="mb-2">Please make sure you have reviewed your application.</p>
              <div className="mt-6 flex justify-between">
                <button
                  onClick={handleBack}
                  className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                >
                  Back
                </button>
                <button
                  onClick={handleConfirm}
                  className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
        {isSubmitting && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full my-8">
              <div className="flex items-center justify-center">
                <svg className="animate-spin h-10 w-10 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l2.121 2.122A8 8 0 004 12H0c0 2.021.678 3.884 1.809 5.291z"></path>
                </svg>
                <p className="text-gray-800 ml-4">Submitting...</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApplyJobModal;
