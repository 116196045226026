import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { parseISO, differenceInDays, isPast } from 'date-fns';
import ApplyJobModal from '../JobseekerDashBoard/ApplyJobModal';

const JobListing = ({ job, user }) => {
  const [isApplyJobModalOpen, setIsApplyJobModalOpen] = useState(false);
  const [daysRemaining, setDaysRemaining] = useState('');
  const [deadlinePassed, setDeadlinePassed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const updateDeadline = () => {
      if (job.deadline_date) {
        const deadlineDate = parseISO(job.deadline_date);
        if (isPast(deadlineDate)) {
          setDeadlinePassed(true);
        } else {
          const daysLeft = Math.max(differenceInDays(deadlineDate, new Date()), 1);
          setDaysRemaining(`${daysLeft} day${daysLeft !== 1 ? 's' : ''} remaining`);
          setDeadlinePassed(false);
        }
      }
    };

    updateDeadline();
    const intervalId = setInterval(updateDeadline, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, [job.deadline_date]);

  const handleApplyClick = () => {
    if (user) {
      setIsApplyJobModalOpen(true); // Open the modal
    } else {
      navigate('/signup/jobseeker');
    }
  };

  const closeApplyJobModal = () => {
    setIsApplyJobModalOpen(false); // Close the modal
  };

  const handleJobTitleClick = () => {
    navigate(`/findjobs/${job.id}/${job.title}`);
  };

  return (
    <div className="border rounded-lg p-4 mb-4 shadow-sm flex flex-col items-center">
      <img 
        src={job.company_logo || 'https://via.placeholder.com/100'}
        alt={`${job.company_name} logo`} 
        className="h-16 w-16 object-cover rounded-full mb-4"
      />
      <h3 
        className="text-lg font-semibold cursor-pointer text-green-400"
        onClick={handleJobTitleClick}
      >
        {job.title}
      </h3>
      <p className="text-sm text-gray-500">{job.work_type} ({job.location})</p>
      <div className="text-sm text-gray-500 mt-2">
        {deadlinePassed ? (
          <span className="font-bold text-red-500">Closed for application</span>
        ) : (
          <>
            <span className="font-bold">Deadline:</span> {daysRemaining}
            <span className="mx-2">·</span>
            <span className="font-bold text-green-500">{job.applicant_count} applicant{job.applicant_count !== 1 ? 's' : ''}</span>
          </>
        )}
      </div>
      <button className="bg-green-400 text-white py-2 px-4 rounded mt-2" onClick={handleApplyClick}>
        {user ? 'Apply' : 'Sign up to Apply'}
      </button>
      {isApplyJobModalOpen && (
        <ApplyJobModal isOpen={isApplyJobModalOpen} onClose={closeApplyJobModal} jobId={job.id} />
      )}
    </div>
  );
};

export default JobListing;
