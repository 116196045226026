import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/hireke HD black.png';

const Footer = ({ user }) => {
  const isLoggedIn = !!user;
  const isJobseeker = isLoggedIn && user.role === 'jobseeker';
  const isEmployer = isLoggedIn && user.role === 'employer';

  return (
    <div className='w-full h-full px-5 py-2 bg-green-400'>
      <div className='border-t border-gray-300 pt-2 md:border-0 md:flex justify-between gap-8'>
        {/* Company Logo & Slogan */}
        <div className='my-1 flex-1 flex flex-col items-center'>
          <img src={logo} alt="hireKE logo" className='w-32 h-auto mb-2' />
          <p className='text-white font-semibold'>Gateway to Kenya's job market</p>
        </div>
        {/* Contact Us */}
        <div className='my-1 flex-1'>
          <h1 className='font-semibold'>Contact Us</h1>
          <ul>
            <li>Address: Juja Town, Ravy Hse., Room P1</li>
            <li>Email: <a href="mailto:support@hireke.com" className='text-blue-500 hover:underline'>support@hireke.com</a></li>
            <li>Tel: +254712932132</li>
          </ul>
        </div>
        {/* Job Seeker */}
        <div className='my-1 flex-1'>
          <h1 className='font-semibold'>Job Seeker</h1>
          <ul>
            {!isJobseeker ? (
              <li><Link to="/signup/jobseeker" className='text-blue-500 hover:underline'>Create Account</Link></li>
            ) : (
              <li><span className='text-gray-500'>Create Account</span></li>
            )}
            <li>
              {isLoggedIn && !isJobseeker ? (
                <Link to="/signup/jobseeker" className='text-blue-500 hover:underline'>Apply for Jobs</Link>
              ) : (
                <span className='text-gray-500'>Apply for Jobs</span>
              )}
            </li>
          </ul>
        </div>
        {/* Employer */}
        <div className='my-1 flex-1'>
          <h1 className='font-semibold'>Employer</h1>
          <ul>
            {!isEmployer ? (
              <li><Link to="/signup/employer" className='text-blue-500 hover:underline'>Create Account</Link></li>
            ) : (
              <li><span className='text-gray-500'>Create Account</span></li>
            )}
            <li>
              {isLoggedIn && !isEmployer ? (
                <Link to="/signup/employer" className='text-blue-500 hover:underline'>Post Jobs</Link>
              ) : (
                <span className='text-gray-500'>Post Jobs</span>
              )}
            </li>
          </ul>
        </div>
      </div>
      <div className='text-center mt-1'>
        <h5>Copyright © 2024 hireKE</h5>
      </div>
    </div>
  );
};

export default Footer;
