// NoAlertsImage.js
import React from 'react';

const NoAlertsImage = () => {
  return (
    <div className="flex flex-col items-center">
      <img src="https://i.pinimg.com/236x/19/03/a9/1903a97ae7855886c6086e1f2e084613.jpg" alt="No alerts" className="w-24 h-24 mb-4" />
      <p className="text-center text-gray-500">No alerts available.</p>
    </div>
  );
};

export default NoAlertsImage;
