import { useState, createContext, useContext, useEffect } from 'react';
import logo from "../assets/images/hireke HD black.png"

const LoadContext = createContext();

export const useLoad = () => useContext(LoadContext);

export const LoadProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000); // Minimum time to display the loader in milliseconds (e.g., 2000ms = 2 seconds)

      return () => clearTimeout(timer);
    }
  }, [loading]);

  return (
    <LoadContext.Provider value={{ loading, setLoading }}>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-5 rounded-lg flex items-center space-x-3">
          <img src={logo} alt="loading..." className='w-10 h-10'/>
            <svg className="animate-spin h-5 w-5 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
            </svg>
            <p>Loading...</p>
          </div>
        </div>
      )}
      {children}
    </LoadContext.Provider>
  );
};
