import React, { useEffect, useState } from 'react';
import { FaChevronDown, FaChevronUp, FaEllipsisH } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAxios from '../../hooks/useAxios';
import { formatDate } from '../../utils/dateUtils';

const TaskList = () => {
  const [tasksByEmployer, setTasksByEmployer] = useState([]);
  const [expandedEmployer, setExpandedEmployer] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState({});
  const { get, patch } = useAxios();

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await get('/team_member/tasks', {
          useAuth: true,
        });
        console.log(response);
        setTasksByEmployer(response);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    fetchTasks();
  }, [get]);

  const toggleEmployer = (employerId) => {
    setExpandedEmployer(expandedEmployer === employerId ? null : employerId);
  };

  const toggleDropdown = (taskId) => {
    setDropdownVisible((prev) => ({
      ...prev,
      [taskId]: !prev[taskId],
    }));
  };

  const markAsCompleted = async (taskId) => {
    try {
      await patch(`/tasks/${taskId}/status`, { is_completed: true }, { useAuth: true });
      setTasksByEmployer((prevTasks) =>
        prevTasks.map((employer) => ({
          ...employer,
          tasks: employer.tasks.map((task) =>
            task.task_id === taskId ? { ...task, is_completed: true } : task
          ),
        }))
      );
      toast.success('Task marked as completed successfully!');
    } catch (error) {
      console.error('Error marking task as completed:', error);
      toast.error('Failed to mark task as completed.');
    }
  };

  return (
    <div className="container mx-auto p-4">
      <ToastContainer />
      <h1 className="text-2xl font-bold mb-4">Team Member Tasks</h1>
      {tasksByEmployer.map((employer) => (
        <div key={employer.employer_id} className="mb-4 border rounded-lg p-4 bg-white shadow">
          <button
            className="text-xl font-semibold w-full text-left flex justify-between items-center"
            onClick={() => toggleEmployer(employer.employer_id)}
          >
            {employer.employer_name}
            {expandedEmployer === employer.employer_id ? (
              <FaChevronUp className="ml-2" />
            ) : (
              <FaChevronDown className="ml-2" />
            )}
          </button>
          {expandedEmployer === employer.employer_id && (
            <div className="mt-4">
              {employer.tasks.length === 0 ? (
                <p className="text-gray-500">No tasks assigned yet.</p>
              ) : (
                employer.tasks.map((task) => (
                  <div key={task.task_id} className="p-2 border-b last:border-none relative">
                    <h2 className="text-lg font-semibold">{task.job_title}</h2>
                    <p>{task.description}</p>
                    <p className="text-sm text-gray-500">Completed: {task.is_completed ? 'Yes' : 'No'}</p>
                    <p className="text-sm text-gray-500">
                      Assigned on: {formatDate(task.created_at)}
                    </p>
                    <p className="text-sm text-gray-500">
                      Deadline: {formatDate(task.deadline)}
                    </p>
                    <button
                      className="absolute top-2 right-2"
                      onClick={() => toggleDropdown(task.task_id)}
                    >
                      <FaEllipsisH />
                    </button>
                    {dropdownVisible[task.task_id] && (
                      <div className="absolute right-2 top-8 bg-white border rounded shadow-md z-10">
                        <button
                          className={`block px-4 py-2 text-left text-sm w-full ${
                            task.is_completed ? 'cursor-not-allowed text-gray-400' : 'hover:bg-gray-100'
                          }`}
                          onClick={() => !task.is_completed && markAsCompleted(task.task_id)}
                          disabled={task.is_completed}
                        >
                          {task.is_completed ? 'Task Completed' : 'Mark as Completed'}
                        </button>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TaskList;
