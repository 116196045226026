import React from 'react';

const Card = ({ title, children }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-6 w-auto md:w-4/5 mx-auto">
      <h2 className="text-2xl font-semibold mb-4 text-center">{title}</h2>
      <div>{children}</div>
    </div>
  );
};

export default Card;
