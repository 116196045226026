import React, { useEffect, useState, useRef, useCallback } from 'react';
import useAxios from '../../hooks/useAxios';
import { useError } from '../../hooks/useError';
import NoAlertsImage from './NoAlertsImage'; // Adjust the import path as needed
import { MdDelete } from 'react-icons/md';
import { io } from 'socket.io-client';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const JobseekerAlertPopup = ({ isOpen, onClose, alerts, setAlerts, setUnreadCount }) => {
  const { get, patch, del } = useAxios();
  const { setError } = useError();
  const [loading, setLoading] = useState(false);
  const popupRef = useRef(null);
  const navigate = useNavigate(); // Initialize useNavigate
  const socket = useRef(null); // Use ref to manage socket connection

  useEffect(() => {
    socket.current = io('http://127.0.0.1:5555');

    const handleUpdate = () => {
      fetchAlerts(); // Optionally refetch alerts
    };

    socket.current.on('job_update', handleUpdate);
    socket.current.on('stage_change', handleUpdate);

    return () => {
      socket.current.off('job_update', handleUpdate);
      socket.current.off('stage_change', handleUpdate);
      socket.current.disconnect(); // Clean up socket connection
    };
  }, []);

  const fetchAlerts = useCallback(async () => {
    setLoading(true);
    try {
      const data = await get('/allalerts', { useAuth: true });
      setAlerts(data);
      setUnreadCount(data.unread_alerts.length);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [get, setAlerts, setUnreadCount, setError]);

  useEffect(() => {
    if (isOpen) {
      fetchAlerts();
    }
  }, [isOpen, fetchAlerts]);

  const markAsRead = useCallback(async (alertId) => {
    try {
      await patch(`/alert/${alertId}`, {}, { useAuth: true });
      setAlerts(prevAlerts => ({
        unread_alerts: prevAlerts.unread_alerts.filter(alert => alert.id !== alertId),
        read_alerts: [...prevAlerts.read_alerts, ...prevAlerts.unread_alerts.filter(alert => alert.id === alertId)]
      }));
      setUnreadCount(prevUnreadCount => prevUnreadCount - 1);
    } catch (error) {
      setError(error.message);
    }
  }, [patch, setAlerts, setUnreadCount, setError]);

  const deleteAlert = useCallback(async (alertId) => {
    try {
      await del(`/deletealert/${alertId}`, { useAuth: true });
      setAlerts(prevAlerts => {
        const updatedUnread = prevAlerts.unread_alerts.filter(alert => alert.id !== alertId);
        const updatedRead = prevAlerts.read_alerts.filter(alert => alert.id !== alertId);
        setUnreadCount(updatedUnread.length);
        return {
          unread_alerts: updatedUnread,
          read_alerts: updatedRead,
        };
      });
    } catch (error) {
      setError(error.message);
    }
  }, [del, setAlerts, setUnreadCount, setError]);

  const handleAlertClick = useCallback(async (alert) => {
    if (alert.alert_type === 'job_match') {
      if (!alert.is_viewed) {
        await markAsRead(alert.id); // Mark alert as read
      }
      navigate(`/jobupdate/${alert.job_id}`);
      onClose(); // Close the popup after navigation
    }
  }, [markAsRead, navigate, onClose]);

  const handleViewJobClick = useCallback(async (alert) => {
    if (!alert.is_viewed) {
      await markAsRead(alert.id); // Mark alert as read
    }
    navigate(`/jobmatch/${alert.job_id}`);
    onClose(); // Close the popup after navigation
  }, [markAsRead, navigate, onClose]);

  const handleViewJobDetailsClick = useCallback(async (alert) => {
    if (!alert.is_viewed) {
      await markAsRead(alert.id); // Mark alert as read
    }
    navigate(`/jobdetails/${alert.job_id}`);
    onClose(); // Close the popup after navigation
  }, [markAsRead, navigate, onClose]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!alerts) return null;

  const allAlerts = alerts.unread_alerts.concat(alerts.read_alerts);

  return (
    isOpen && (
      <div className="fixed top-24 right-4 md:right-32 z-50" ref={popupRef}>
        <div className="bg-white rounded-lg shadow-lg p-3 w-64 md:w-80 max-h-80 overflow-y-auto">
          <div className="flex justify-between items-center mb-2">
            <button
              onClick={() => {
                setUnreadCount(0); // Reset unread count
                onClose();
              }}
              className="text-gray-500 hover:text-gray-700 ml-auto text-lg"
              style={{ fontSize: '1.5rem' }}
            >
              &times;
            </button>
          </div>
          {loading ? (
            <div className="text-center text-sm">Loading...</div>
          ) : allAlerts.length === 0 ? (
            <NoAlertsImage />
          ) : (
            <div>
              {allAlerts.map((alert) => (
                <div
                  key={alert.id}
                  className={`relative p-2 flex flex-col justify-between items-start ${alert.is_viewed ? 'bg-white' : 'bg-yellow-100'} border-b`}
                >
                  <div className="flex justify-between items-center w-full">
                    <div>
                      <p className={`font-semibold text-sm ${alert.is_viewed ? 'text-gray-800' : 'text-black'}`}>
                        {alert.job_title}
                      </p>
                      <p className="text-xs text-gray-600">{alert.alert_type}</p>
                      <p className={`text-sm ${alert.is_viewed ? 'font-normal' : 'font-bold'}`}>{alert.message}</p>
                    </div>
                    <div className="flex flex-col items-end">
                      <p className="text-xs text-gray-600">{new Date(alert.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                      <button
                        className="text-gray-500 hover:text-gray-700 mt-1"
                        onClick={(e) => { e.stopPropagation(); deleteAlert(alert.id); }}
                      >
                        <MdDelete />
                      </button>
                    </div>
                  </div>
                  {alert.alert_type === 'job_match' && (
                    <button
                      onClick={(e) => { e.stopPropagation(); handleViewJobClick(alert); }}
                      className="mt-2 bg-green-400 text-white px-3 py-1 rounded text-sm hover:bg-blue-600"
                    >
                      View Job
                    </button>
                  )}
                  {alert.alert_type === 'application_submission' && (
                    <button
                      onClick={(e) => { e.stopPropagation(); handleViewJobDetailsClick(alert); }}
                      className="mt-2 bg-green-400 text-white px-3 py-1 rounded text-sm hover:bg-blue-600"
                    >
                      View Job Details
                    </button>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default JobseekerAlertPopup;
