import React, { useState,useEffect, useCallback } from 'react';
import Sidebar from './Sidebar';
import RecruitmentStages from './RecruitmentStages';
import ApplicantsTable from './ApplicantsTable';
import useAxios from '../../hooks/useAxios';
import { useError } from '../../hooks/useError';
import { useLoad } from '../../hooks/useLoad';
import { useDebounce } from '../../hooks/useDebounce';
import ApplicantDetailsModal from '../../EmployerDashBoard/Applicants/components/ApplicantDetailsModal';

const Main = () => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedStage, setSelectedStage] = useState(null);  
  const [currentApplicant, setCurrentApplicant] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { get } = useAxios()
  const { setError } = useError()
  const{setLoading}=useLoad()
 const [stages, setStages] = useState([]);
  const [applicants, setApplicants] = useState([]);
const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const debouncedSearch = useDebounce(search, 500);


  const handleJobSelect = (jobId) => {
    setSelectedJob(jobId);
    setSelectedStage(null); // Reset stage when a new job is selected
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleNextPrev = async (direction) => {
    if (currentApplicant) {
      const currentIndex = applicants.findIndex(app => app.id === currentApplicant.id);
      const nextIndex = (currentIndex + direction + applicants.length) % applicants.length;
      fetchApplicantDetails(applicants[nextIndex].id);
    }
  };

  useEffect(() => {
    if (!selectedJob) return;

    const fetchStages = async () => {
      try {
        setLoading(true)
        const response = await get(`/jobs/${selectedJob}/recruitment_stages`, { useAuth: true });
        setStages(response);
        setLoading(false)
      } catch (error) {
        setError(error)
        setLoading(false)
      }
    };

    fetchStages();
  }, [selectedJob, get,setError,setLoading]);

  const handleStageSelect = (stageId) => {
    setSelectedStage(stageId);
  };
  const fetchApplicantDetails = async (id) => {
    try {
      const data = await get(`/applicant/${id}`, { useAuth: true });
      setCurrentApplicant(data);
      setModalIsOpen(true);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    if (!selectedJob || !selectedStage) return;

    const fetchApplicants = async () => {
      setLoading(true)
      try {
        const response = await get(`/applicants?job_id=${selectedJob}&stage_id=${selectedStage}`, { useAuth: true });
        setApplicants(response.applicants);
        setTotal(response.total);
        setLoading(false);
      } catch (error) {
        setError(error)
        setLoading(false)
      }
    };

    fetchApplicants();
  }, [selectedJob, selectedStage, get]);

  const handleApplicantClick = (id) => {
    fetchApplicantDetails(id);
  };

  
  
  
  

  const updateStageCounts = (updatedCounts) => {
    setStages(prevStages =>
      prevStages.map(stage =>
        updatedCounts[stage.id] !== undefined
          ? { ...stage, applicant_count: updatedCounts[stage.id] }
          : stage
      )
    );
  };

  const updateApplicantState = (applicantId, updatedFields) => {
    setApplicants(prevApplicants =>
      prevApplicants.map(applicant =>
        applicant.id === applicantId ? { ...applicant, ...updatedFields } : applicant
      )
    );
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to first page on new search
  };

   const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setPage(1); // Reset to first page on limit change
   };
  
   const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  
  return (
    <div className="flex">
      {currentApplicant && (
        <ApplicantDetailsModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          applicant={currentApplicant}
          recruitmentStages={stages}
          setRecruitmentStages={setStages}
          updateApplicantState={updateApplicantState}
          updateStageCounts={updateStageCounts}
          setApplicants={setApplicants}
          onNext={() => handleNextPrev(1)}
          onPrev={() => handleNextPrev(-1)}
        />
      )}
      <div className="w-1/4">
        <Sidebar onJobSelect={handleJobSelect} />
      </div>
      <div className="w-3/4 p-4">
        {selectedJob ? (
          <>
            <RecruitmentStages onStageSelect={handleStageSelect} stages={stages} />
             <ApplicantsTable  
              handleApplicantClick={handleApplicantClick}
              search={search}
              onSearchChange={handleSearchChange}
              limit={limit}
              onLimitChange={handleLimitChange}
              page={page}
              total={total}
              applicants={applicants}
              modalIsOpen={modalIsOpen}
              onPageChange={handlePageChange} />
          </>
        ) : (
          <div className="bg-white shadow-lg p-4">
            <h2 className="text-lg font-bold">Welcome</h2>
            <p>Select a job to start reviewing applicants.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Main;
