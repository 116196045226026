import React from 'react';
import JobCategories from '../../components/JobCategories'; // Ensure the path is correct

const CompaniesPage = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <main className="container mx-auto py-2">
        <JobCategories layout="grid" />
      </main>
    </div>
  );
};

export default CompaniesPage;
