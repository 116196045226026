import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaArrowLeft } from 'react-icons/fa';
import useAxios from '../hooks/useAxios';
import { useError } from '../hooks/useError';
import logo from '../assets/images/hireke HD black.png';

import login from '../assets/images/businessman-1765664_1920.png';

const Login = ({ setUser, fetchUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [showResetForm, setShowResetForm] = useState(false);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { post } = useAxios();
  const { setError } = useError();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await post('/login', { email, password }, { useAuth: false });
      localStorage.setItem('access_token', data.access_token);
      await fetchUser(); // Fetch the user session after storing the access token
      if (data.message === 'Employer') {
        navigate('/myjobs');
      } else if (data.message === 'Jobseeker') {
        navigate('/findjobs');
      } else if (data.message === "Admin") {
        navigate('/admin/blog');
      } else if (data.message === "Team member") {
        navigate('/team member/my task');
      }
    } catch (error) {      
        const errorMessage = error.response.data.message;
        toast.error(errorMessage);
      // if (error.response) {
      //   toast.error('Incorrect email or password. Please try again.');
      // } else {
      //   toast.error('An error occurred. Please try again.');
      // }
    }
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await post('/forgotpassword', { email }, { useAuth: false });
      setShowOtpForm(true);
      setShowResetForm(false);
    } catch (error) {
      console.error('Password reset request failed:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleResetSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await post('/changepassword', { email, otp, new_password: newPassword }, { useAuth: false });
      toast.success('Password reset successfully.');
      setShowOtpForm(false);
      setShowResetForm(false);
    } catch (error) {
      console.error('Password reset failed:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="lg:flex items-center min-h-screen bg-green-100 ">
      <ToastContainer />
      <button onClick={() => navigate(-1)} className="absolute top-0 left-0 mt-2 ml-2">
        <FaArrowLeft className="w-6 h-6" />
      </button>
      <div className="bg-white w-full lg:w-1/2 p-8 flex justify-center items-center h-screen ">
        <div className="mt-32 lg:mt-0">
          <div className="flex justify-center mb-6">
            <img src={logo} alt="HireKE" className="h-12" />
          </div>
          {showOtpForm ? (
            <div className="bg-white w-full p-8 flex justify-center items-center shadow-lg rounded-lg">
              <div className="w-full">
                <h2 className="text-2xl font-bold mb-4 text-center">Reset Password</h2>
                <p className="text-center mb-4">OTP has been sent to {email}</p>
                <form className="space-y-6" onSubmit={handleResetSubmit}>
                  <input
                    className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded"
                    type="text"
                    placeholder="Enter the OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                  <input
                    className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-2"
                    type="password"
                    placeholder="Enter your new password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                  <div className="flex justify-center items-center">
                    <button
                      type="submit"
                      className="group relative w-68 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-300 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                      disabled={loading}
                    >
                      {loading ? 'Resetting Password...' : 'Reset Password'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : showResetForm ? (
            <div className="bg-white w-full p-8 flex justify-center items-center shadow-lg rounded-lg">
              <div className="w-full">
                <h2 className="text-2xl font-bold mb-4 text-center">Forgot Password</h2>
                <form className="space-y-6" onSubmit={handleEmailSubmit}>
                  <input
                    className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded"
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <div className="flex justify-center items-center">
                    <button
                      type="submit"
                      className="group relative w-68 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-300 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                      disabled={loading}
                    >
                      {loading ? 'Sending OTP...' : 'Send OTP'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <form className="mt-8 space-y-6 object-center" onSubmit={handleSubmit}>
              <input
                className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded"
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <div className="flex justify-center items-center text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded gap-3">
                <input
                  className="border-none"
                  type={isOpen ? "text" : 'password'}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div onClick={() => setIsOpen(!isOpen)}>
                  {isOpen ? (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>
                  )}
                </div>
              </div>
              <div className="mt-4 flex justify-between font-semibold text-sm">
                <button type="button" onClick={() => setShowResetForm(true)} className="text-green-600 hover:text-green-700 hover:underline hover:underline-offset-4">
                  Forgot Password?
                </button>
              </div>
              <div className="flex justify-center items-center">
                <button type="submit" className="group relative w-68 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-300 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 bold">
                  Log in
                </button>
                  </div>
                Don't have an Account? <Link to="/signup" className="text-green-500">sign Up</Link>
            </form>
          )}
        </div>
      </div>
      <div className="hidden lg:block bg-green h-screen w-full lg:w-1/2">
        <img src={login} alt='login' className="w-full h-full object-cover" />
      </div>
    </div>
  );
};

export default Login;
