import React, { useEffect, useState } from 'react';
import useAxios from '../../hooks/useAxios';

const Sidebar = ({ onJobSelect }) => {
  const [employers, setEmployers] = useState([]);
  const { get } = useAxios();

  useEffect(() => {
    const fetchEmployers = async () => {
      try {
        const response = await get('/team_member/tasks', { useAuth: true });
        setEmployers(response);
      } catch (error) {
        console.error('Error fetching employers:', error);
      }
    };

    fetchEmployers();
  }, [get]);

  return (
    <div className=" p-4 bg-white h-screen">
      {employers.map((employer) => (
        <div key={employer.employer_id} className="mb-6">
          <h2 className="text-xl font-bold mb-2">{employer.employer_name}</h2>
          <ul>
            {employer.tasks.map((task) => (
              <li 
                key={task.job_id} 
                className="cursor-pointer hover:underline mb-1 px-4" 
                onClick={() => onJobSelect(task.job_id)}
              >
                {task.job_title}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
