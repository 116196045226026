import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxios from '../hooks/useAxios';
import { FaArrowLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { useError } from '../hooks/useError';
import { useLoad } from '../hooks/useLoad';

const EmployerSignup = ({ fetchUser }) => {
  
  const inputRef = useRef(null);
  const [step, setStep] = useState(1);
  const [otpSet, setOtp] = useState(['', '', '', '', '', '']);
  const inputs = useRef([]);
  const { post } = useAxios();  
  const { setLoading } = useLoad();
  const { setError } = useError();
  const [formData, setFormData] = useState({
    company_name: '',
    industry: '',
    company_email: '',
    company_phone_number: '',
    type_employer: 'Private', // Default to 'Private'
    number_of_employees: '',
    location: '',
    bio: '',
    website: '',
    password: '',
    recruitment_stages: [{ stage_name: 'New Application', stage_description: 'Initial stage for new applications', stage_order: 1 }]
  });
  const [companyLogo, setCompanyLogo] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null
      });
    }
  };

  const handleImageChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    setCompanyLogo(file);
    setErrors(prev => ({ ...prev, companyLogo: '' })); // Clear error if a file is selected
  }
};

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleChangeOtp = (e, index) => {
    const value = e.target.value;
    if (value.length <= 1) {
      const newOtp = [...otpSet];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < 5) {
        inputs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otpSet[index] && index > 0) {
      inputs.current[index - 1].focus();
    }
  };

  // Handle recruitment stage changes
  const handleRecruitmentStageChange = (index, field, value) => {
    const updatedStages = formData.recruitment_stages.map((stage, i) =>
      i === index ? { ...stage, [field]: value } : stage
    );
    setFormData({
      ...formData,
      recruitment_stages: updatedStages
    });
  };

  // Add a new recruitment stage
  const addRecruitmentStage = () => {
    setFormData({
      ...formData,
      recruitment_stages: [
        ...formData.recruitment_stages,
        { stage_name: '', stage_description: '', stage_order: formData.recruitment_stages.length + 1 }
      ]
    });
  };

  // Remove a recruitment stage
  const removeRecruitmentStage = (index) => {
    const updatedStages = formData.recruitment_stages.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      recruitment_stages: updatedStages.map((stage, i) => ({ ...stage, stage_order: i + 1 }))
    });
  };

  // Validate form inputs based on the current step
  const validate = (currentStep) => {
    const newErrors = {};
    if (currentStep === 1) {
      if (!formData.company_name) newErrors.company_name = 'Company name is required';
      if (!formData.industry) newErrors.industry = 'Industry is required';
      if (!formData.company_email) newErrors.company_email = 'Company email is required';
      if (!formData.company_phone_number || formData.company_phone_number.length < 10) newErrors.company_phone_number = 'Phone number must be at least 10 digits';
    } else if (currentStep === 2) {
      if (!formData.location) newErrors.location = 'Location is required';
      if (!formData.bio) newErrors.bio = 'Bio is required';
      if (!formData.password) newErrors.password = 'Password is required';
      
    }
    return newErrors;
  };

  // Convert form data to FormData object
  const convertToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        formData.append(key, JSON.stringify(data[key])); // Stringify arrays (recruitment_stages)
      } else {
        formData.append(key, data[key]);
      }
    });
    if (companyLogo) {
      formData.append('company_logo', companyLogo);
    }
    return formData;
  };

  // Handle form submission
const handleSubmit = async (e) => {
  e.preventDefault();
  
 
  
  // Validate the form
  const validationErrors = validate(step);
  if (Object.keys(validationErrors).length > 0) {
    setErrors(validationErrors);
    return; // Stop the process if validation errors exist
  }

  if (step === 1) {
    // Send data and get OTP
    try {
      const formDataObj = convertToFormData(formData);
      setLoading(true);
      const response = await post('/employer_signup', formDataObj, { useAuth: false, headers: { 'Content-Type': 'multipart/form-data' } });
      
      if (response[0].message === 'OTP sent') {
        // OTP sent successfully, proceed to the next step
        setStep(step + 1);
        toast.success(`OTP sent to ${formData.company_email}`);
      } else {
        // Handle error
        toast.error(response[0].message);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      toast.error(errorMessage);
    } finally {
      setLoading(false); // Ensure loading is false after the try-catch block
    }
  } else if (step < 4) {
    setStep(step + 1);
  } else if (step === 4) {
    // Verify OTP
    try {
      setLoading(true);
      const otp = otpSet.join('');
      const formDataObj = convertToFormData({ ...formData, otp });
      
      const response = await post('/verify_employer', formDataObj, { useAuth: false, headers: { 'Content-Type': 'multipart/form-data' } });
      
      if (response.message.includes('Employer registered successfully')) {
        localStorage.setItem('access_token', response.access_token);
        await fetchUser();
        navigate('/login'); // Redirect to login page after successful verification
      } else {
        const errorMessage = response.data.message;
        toast.error(errorMessage);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      toast.error(errorMessage);
    } finally {
      setLoading(false); // Ensure loading is false after the try-catch block
    }
  }
};


  const getInitials = (name) => {
    if (!name) return '';
    const words = name.split(' ');
    return words.map(word => word[0]).join('').toUpperCase();
  };
  
  // Render different steps
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Step 1: Company Information</h2>
            <label className="block text-gray-700">Company Logo</label>
            <div onClick={handleImageClick} className='bg-[#fff] h-[100px] w-[100px]  my-4 rounded-[5px] cursor-pointer mx-auto'>
              <div>
                {companyLogo ? (
                  <img className='w-screen' src={URL.createObjectURL(companyLogo)} alt="Company Logo"/>
                ) : (
                  <img className='' src="https://iili.io/JVksC6Q.png" alt="Placeholder Logo" />
                )}
              </div>
              <input type="file" ref={inputRef} onChange={handleImageChange} className='hidden'  />
              
              {errors.companyLogo && <p className="text-red-500 text-sm">{errors.companyLogo}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Company Name</label>
              <input
                type="text"
                name="company_name"
                value={formData.company_name}
                onChange={handleChange}
                className={`mt-1 block w-full rounded-md border ${errors.company_name ? 'border-red-500' : 'border-gray-300'} shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 py-2`}
              />
              {errors.company_name && <p className="text-red-500 text-sm">{errors.company_name}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Industry</label>
              <select
                name="industry"
                value={formData.industry}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 py-2"
              >
                <option value="">Select Industry</option>
                <option value="Agriculture, Forestry, & Fishing">Agriculture, Forestry, & Fishing</option>
                <option value="Arts, Entertainment, & Recreation">Arts, Entertainment, & Recreation</option>
                <option value="Automotive">Automotive</option>
                <option value="Building and Construction">Building and Construction</option>
                <option value="Education & Training">Education & Training</option>
                <option value="Energy">Energy</option>
                <option value="Financial & Insurance Services">Financial & Insurance Services</option>
                <option value="Health">Health</option>
                <option value="Hospitality">Hospitality</option>
                <option value="Informal Sector (Jua Kali)">Informal Sector (Jua Kali)</option>
                <option value="Information & Communication Technology">Information & Communication Technology</option>
                <option value="Manufacturing">Manufacturing</option>
                <option value="Mining & Minerals">Mining & Minerals</option>
                <option value="Professional, Scientific, & Technical Activities">Professional, Scientific, & Technical Activities</option>
                <option value="Public Administration & Defense">Public Administration & Defense</option>
                <option value="Real Estate">Real Estate</option>
                <option value="Social Work Activities">Social Work Activities</option>
                <option value="Tourism">Tourism</option>
                <option value="Transportation & Storage">Transportation & Storage</option>
                <option value="Waste Management">Waste Management</option>
                <option value="Water Supply">Water Supply</option>
                <option value="Wholesale & Retail Trade">Wholesale & Retail Trade</option>
              </select>
              {errors.industry && <p className="text-red-500 text-sm">{errors.industry}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Company Email</label>
              <input
                type="email"
                name="company_email"
                value={formData.company_email}
                onChange={handleChange}
                className={`mt-1 block w-full rounded-md border ${errors.company_email ? 'border-red-500' : 'border-gray-300'} shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 py-2`}
              />
              {errors.company_email && <p className="text-red-500 text-sm">{errors.company_email}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Phone Number</label>
              <input
                type="text"
                name="company_phone_number"
                value={formData.company_phone_number}
                onChange={handleChange}
                className={`mt-1 block w-full rounded-md border ${errors.company_phone_number ? 'border-red-500' : 'border-gray-300'} shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 py-2`}
              />
              {errors.company_phone_number && <p className="text-red-500 text-sm">{errors.company_phone_number}</p>}
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Step 2: Additional Information</h2>
            <div className="mb-4">
              <label className="block text-gray-700">Type of Employer</label>
              <select
                name="type_employer"
                value={formData.type_employer}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 py-2"
              >
                <option value="Private">Private</option>
                <option value="Government">Government</option>
                <option value="Recruitment Agency">Recruitment Agency</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Number of Employees</label>
              <input
                type="number"
                name="number_of_employees"
                value={formData.number_of_employees}
                onChange={handleChange}
                className={`mt-1 block w-full rounded-md border ${errors.number_of_employees ? 'border-red-500' : 'border-gray-300'} shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 py-2`}
              />
              {errors.number_of_employees && <p className="text-red-500 text-sm">{errors.number_of_employees}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Location</label>
              <select
                name="location"
                value={formData.location}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 py-2"
              >
                <option value="">Select Location</option>
                <option value="Bomet">Bomet</option>
                <option value="Bungoma">Bungoma</option>
                <option value="Busia">Busia</option>
                <option value="Elgeyo Marakwet">Elgeyo Marakwet</option>
                <option value="Embu">Embu</option>
                <option value="Garissa">Garissa</option>
                <option value="Homa Bay">Homa Bay</option>
                <option value="Isiolo">Isiolo</option>
                <option value="Kajiado">Kajiado</option>
                <option value="Kakamega">Kakamega</option>
                <option value="Kericho">Kericho</option>
                <option value="Kerugoya">Kerugoya</option>
                <option value="Kisii">Kisii</option>
                <option value="Kisumu">Kisumu</option>
                <option value="Kitui">Kitui</option>
                <option value="Kivaa">Kivaa</option>
                <option value="Kiambu">Kiambu</option>
                <option value="Kilifi">Kilifi</option>
                <option value="Kirinyaga">Kirinyaga</option>
                <option value="Kwale">Kwale</option>
                <option value="Laikipia">Laikipia</option>
                <option value="Lamu">Lamu</option>
                <option value="Machakos">Machakos</option>
                <option value="Makueni">Makueni</option>
                <option value="Mandera">Mandera</option>
                <option value="Marsabit">Marsabit</option>
                <option value="Meru">Meru</option>
                <option value="Migori">Migori</option>
                <option value="Mombasa">Mombasa</option>
                <option value="Murang'a">Murang'a</option>
                <option value="Nairobi">Nairobi</option>
                <option value="Nakuru">Nakuru</option>
                <option value="Nandi">Nandi</option>
                <option value="Narok">Narok</option>
                <option value="Nyamira">Nyamira</option>
                <option value="Nyandarua">Nyandarua</option>
                <option value="Nyeri">Nyeri</option>
                <option value="Samburu">Samburu</option>
                <option value="Taita-Taveta">Taita-Taveta</option>
                <option value="Tana River">Tana River</option>
                <option value="Tharaka Nithi">Tharaka Nithi</option>
                <option value="Trans Nzoia">Trans Nzoia</option>
                <option value="Turkana">Turkana</option>
                <option value="Uasin Gishu">Uasin Gishu</option>
                <option value="Vihiga">Vihiga</option>
                <option value="Wajir">Wajir</option>
                <option value="West Pokot">West Pokot</option>
              </select>
              {errors.location && <p className="text-red-500 text-sm">{errors.location}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Bio</label>
              <textarea
                name="bio"
                value={formData.bio}
                onChange={handleChange}
                className={`mt-1 block w-full rounded-md border ${errors.bio ? 'border-red-500' : 'border-gray-300'} shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 py-2`}
              ></textarea>
              {errors.bio && <p className="text-red-500 text-sm">{errors.bio}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Website (optional)</label>
              <input
                type="text"
                name="website"
                value={formData.website}
                onChange={handleChange}
                className={`mt-1 block w-full rounded-md border ${errors.website ? 'border-red-500' : 'border-gray-300'} shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 py-2`}
              />
              {errors.website && <p className="text-red-500 text-sm">{errors.website}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Password</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className={`mt-1 block w-full rounded-md border ${errors.password ? 'border-red-500' : 'border-gray-300'} shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 py-2`}
              />
              {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Step 3: Recruitment Stages</h2>
            {formData.recruitment_stages.map((stage, index) => (
              <div key={index} className="mb-4 border p-4 rounded-md shadow-sm">
                <div className="mb-2">
                  <label className="block text-gray-700">Stage Name</label>
                  <input
                    type="text"
                    value={stage.stage_name}
                    onChange={(e) => handleRecruitmentStageChange(index, 'stage_name', e.target.value)}
                    className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 py-2"
                  />
                </div>
                <div className="mb-2">
                  <label className="block text-gray-700">Stage Description</label>
                  <input
                    type="text"
                    value={stage.stage_description}
                    onChange={(e) => handleRecruitmentStageChange(index, 'stage_description', e.target.value)}
                    className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 py-2"
                  />
                </div>
                {index > 0 && (
                  <button
                    type="button"
                    onClick={() => removeRecruitmentStage(index)}
                    className="bg-red-500 text-white px-4 py-2 rounded-md"
                  >
                    Remove Stage
                  </button>
                )}
              </div>
            ))}
            <button
              type="button"
              onClick={addRecruitmentStage}
              className="bg-green-500 text-white px-4 py-2 rounded-md"
            >
              Add Stage
            </button>
          </div>
        );
      case 4:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Step 4: Verify OTP</h2>
            <div className="mb-4">
              <label className="block text-gray-700">Enter OTP</label>
              <div className="flex space-x-2 mt-1">
                {otpSet.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    value={digit}
                    onChange={(e) => handleChangeOtp(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(el) => (inputs.current[index] = el)}
                    className="w-12 h-12 text-center rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    maxLength={1}
                  />
                ))}
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-green-100">
      <div className="absolute top-4 left-4">
        <FaArrowLeft className="text-2xl cursor-pointer" onClick={() => navigate(-1)} />
      </div>
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
        <form onSubmit={handleSubmit}>
          {renderStep()}
          <div className="mt-4 flex justify-between">
            {step >= 2 && step < 4 && (
              <button
                type="button"
                onClick={() => setStep(step - 1)}
                className="bg-gray-500 text-white px-4 py-2 rounded-md"
              >
                Back
              </button>
            )}
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              {step === 1 ? 'Send Otp' : step < 4 ? 'Next' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmployerSignup;
