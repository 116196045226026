import React, { useState } from 'react';
import { XMarkIcon, UserIcon, ArrowRightOnRectangleIcon, CogIcon, PencilIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { IoCameraOutline } from "react-icons/io5";



const ProfilePopup = ({ isOpen, onClose, user, logout, popupRef }) => {
  const navigate = useNavigate();

  if (!isOpen) return null;

  const renderEmployerProfile = () => (
    <>
     <div className="relative w-16 h-16 mx-auto">
      <img src={user.image} alt="Profile" className="w-full h-full rounded-full" />
      <div className="absolute bottom-0 right-0 p-1 bg-white rounded-full">
        <PencilIcon 
          className="h-6 w-6 cursor-pointer text-gray-500 hover:text-gray-700"
          onClick={() => { navigate("/edit-profile"); onClose(); }}
        />
      </div>
    </div>
      <p><strong>Name:</strong> {user.name}</p>
      <p><strong>Phone No:</strong> {user.phone_number}</p>
      <p><strong>Location:</strong> {user.location}</p>
      <button 
        onClick={logout} 
        className="flex items-center space-x-1 p-1 mt-4 text-gray-700 hover:bg-red-500 rounded-md w-full text-left"
      >
        <ArrowRightOnRectangleIcon className="h-4 w-4" />
        <span className="text-sm">Logout</span>
      </button>
    </>
  );

  const renderJobseekerProfile = () => (
    <>
      <div className="flex items-center space-x-2 mb-4 relative">
        <div className="relative">
          <img src={user.image} alt="Profile" className="w-8 h-8 rounded-full" />
          <span className="absolute bottom-0 right-0 bg-green-500 rounded-full h-2.5 w-2.5 border-2 border-white"></span>
        </div>
        <span className="font-medium text-sm">{`${user.first_name} ${user.surname}`}</span>
      </div>
      <div className="space-y-1">
        <button 
          onClick={() => { navigate('/my-profile'); onClose(); }} 
          className="flex items-center space-x-1 p-1 text-gray-700 hover:bg-gray-100 rounded-md w-full text-left"
        >
          <UserIcon className="h-4 w-4" />
          <span className="text-sm">Profile</span>
        </button>
        <button 
          onClick={() => { navigate('/update-profile'); onClose(); }} 
          className="flex items-center space-x-1 p-1 text-gray-700 hover:bg-gray-100 rounded-md w-full text-left"
        >
          <CogIcon className="h-4 w-4" />
          <span className="text-sm">Settings</span>
        </button>
        <button 
          onClick={logout} 
          className="flex items-center space-x-1 p-1 text-gray-700 hover:bg-red-500 rounded-md w-full text-left"
        >
          <ArrowRightOnRectangleIcon className="h-4 w-4" />
          <span className="text-sm">Logout</span>
        </button>
      </div>
    </>
  );

  const renderAdminProfile = () => (
    <>
      <div className="relative w-16 h-16 mx-auto">
      <img src={user.image} alt="Profile" className="w-full h-full rounded-full" />
      <div className="absolute bottom-0 right-0 p-1 bg-white rounded-full">
        <PencilIcon 
          className="h-6 w-6 cursor-pointer text-gray-500 hover:text-gray-700"
          onClick={() => { navigate("/admin/edit profile"); onClose(); }}
        />
      </div>
    </div>
      <p><strong>Name:</strong> {`${user.first_name} ${user.last_name}`}</p>
      <p><strong>Email:</strong> {user.email}</p>
      <button 
        onClick={logout} 
        className="flex items-center space-x-1 p-1 mt-4 text-gray-700 hover:bg-red-500 rounded-md w-full text-left"
      >
        <ArrowRightOnRectangleIcon className="h-4 w-4" />
        <span className="text-sm">Logout</span>
      </button>
    </>
  );

  const renderTeamMemberProfile = () => (
    <>
      <div className="relative w-16 h-16 mx-auto">
      <img src={user.image} alt="Profile" className="w-full h-full rounded-full" />
      <div className="absolute bottom-0 right-0 p-1 bg-white rounded-full">
        <PencilIcon 
          className="h-6 w-6 cursor-pointer text-gray-500 hover:text-gray-700"
          onClick={() => { navigate("/team member/Edit my details"); onClose(); }}
        />
      </div>
    </div>
      <p><strong>Name:</strong> {`${user.username}`}</p>
      <p><strong>Email:</strong> {user.email}</p>
      <button 
        onClick={logout} 
        className="flex items-center space-x-1 p-1 mt-4 text-gray-700 hover:bg-red-500 rounded-md w-full text-left"
      >
        <ArrowRightOnRectangleIcon className="h-4 w-4" />
        <span className="text-sm">Logout</span>
      </button>
    </>
  );

  return (
    <div ref={popupRef} className="fixed inset-0 z-30 flex justify-end items-start bg-black bg-opacity-50 pt-20">
      <div className="bg-white rounded-lg p-4 shadow-lg relative mx-4 my-8 w-full max-w-[14rem]">
        <XMarkIcon className="h-6 w-6 absolute top-4 right-4 cursor-pointer" onClick={onClose} />
        <div>
          {user.role === 'employer' && renderEmployerProfile()}
          {user.role === 'jobseeker' && renderJobseekerProfile()}
          {user.role === 'admin' && renderAdminProfile()}
          {user.role === 'team member' && renderTeamMemberProfile()}
        </div>
      </div>
    </div>
  );
};

export default ProfilePopup;
