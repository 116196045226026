import React, { useState, useEffect } from 'react';
import useAxios from '../../hooks/useAxios';
import { useLoad } from '../../hooks/useLoad';
import { useError } from '../../hooks/useError';

const RecruitmentStages = ({ stages ,onStageSelect }) => {
 
  

  

  return (
    <div className='w-full bg-white flex justify-between items-center px-6 border-2 border-gray-500 py-2 mb-3'>
            
            {stages.map((stage,index) => (
              <div
                key={stage.id}
                 className={`p-4 cursor-pointer ${index !== stages.length - 1 ? 'border-r' : ''} border-gray-300 flex items-center`}
                onClick={() => {
                   onStageSelect(stage.id)
                }}
              >
                <div className='text-center'>
                    <div className='font-bold'>{stage.stage_name}</div>
                    <div>{stage.applicant_count || 0}</div>
                  </div>
              </div>
            ))}
          </div>
  );
};

export default RecruitmentStages;
